import { RuleModel } from '../../interfaces/flow';
import { RetentionModel } from '../../interfaces/retentionFlow';
import { RulesDataModel } from '../../providers/rulesFlow/interfaces';

export const handleRulesData = (data: RuleModel[]) => {
  return {
    rules: data,
    rulesNames: data.map((rule) => ({
      label: rule.name,
      value: rule.name,
    })),
  } as RulesDataModel;
};

export const handleFormDataToRetentionModel = (data: RetentionModel) => {
  return {
    description: data.description,
    nodes: data.nodes,
  };
};

export const emptyRetentionFlow: RetentionModel = {
  id: '',
  version: '',
  created_at: '',
  modified_at: '',
  modified_by: '',
  description: '',
  status: '',
  nodes: [
    {
      title: '',
      operator: '',
      action: 'approve',
      error_policy: '',
      terms: [
        {
          complement: false,
          error_policy: '',
          rule: {
            name: '',
          },
        },
      ],
    },
  ],
};
