import React, { useState, useEffect } from 'react';
import AutoSuggest from 'react-autosuggest';
import { trexApi } from '../../../services/api';
import { getUUID } from '../../../services/storage';

import * as I from '../../../interfaces/components/base/autocomplete';
import { getFlowsSuggestions } from './utils';
import Container from './styles';

const AutocompleteFlows: React.FC<I.AutoCompleteProps> = ({
  action,
  name = '',
  defaultValue = '',
  disabled = false,
  isRetention,
}) => {
  const UUID = getUUID();
  const [list, setList] = useState<I.AutoCompleteDescription[]>([
    { id: '', description: '' },
  ]);
  const [dataValue, setDataValue] = useState('');
  const [suggestions, setSuggestions] = useState<I.AutoCompleteDescription[]>([
    { id: '', description: '' },
  ]);
  const url = isRetention ? 'retention-flows' : 'flows';

  const getFlow = (): Promise<React.ReactNode> =>
    new Promise((resolve, reject) => {
      trexApi
        .get(`${url}/${defaultValue}`, {
          headers: {
            'X-SetID': UUID,
          },
        })
        .then((result) => {
          if (result.data) {
            setDataValue(result.data.description);
            setList(result.data);
          }
        })
        .catch(() => {
          reject();
        });
    });

  const callFlowsAPI = (): Promise<React.ReactNode> =>
    new Promise((resolve, reject) => {
      trexApi
        .get(url, {
          params: {
            page: 1,
            'page-size': 10,
            search: dataValue,
          },
          headers: {
            'X-SetID': UUID,
          },
        })
        .then((result) => {
          if (result.data.items) {
            setList(result.data.items);
          }
        })
        .catch(() => {
          reject();
        });
    });

  const callFlowsAction = (suggestion: I.AutoCompleteDescription): void => {
    if (action) {
      action(suggestion.id, suggestion.description);
    }
  };

  useEffect(() => {
    callFlowsAPI();
  }, [dataValue]);

  useEffect(() => {
    if (defaultValue) {
      getFlow();
    } else {
      setDataValue('');
    }
  }, [defaultValue]);

  return (
    <Container>
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={(): void => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }): void => {
          setDataValue(value);
          setSuggestions(getFlowsSuggestions(value, list));
        }}
        onSuggestionSelected={(_, { suggestion }): void =>
          callFlowsAction(suggestion)
        }
        getSuggestionValue={(suggestion): string => suggestion.description}
        renderSuggestion={(suggestion): React.ReactElement => (
          <span>{`${suggestion.description} (ID: ${suggestion.id.substring(
            0,
            6
          )}...)`}</span>
        )}
        inputProps={{
          id: 'suggestionFlowField',
          value: dataValue,
          placeholder: 'Pesquisar',
          name: name || 'retentionFlow',
          onChange: (_, { newValue }): void => {
            setDataValue(newValue);
          },
          disabled: !!disabled,
        }}
        highlightFirstSuggestion
      />
    </Container>
  );
};

export default AutocompleteFlows;
