import React from 'react';
import { Button, Icon, Stack } from '@chakra-ui/react';
import { BsTrashFill } from 'react-icons/bs';
import { MdOutlineAddCircleOutline } from 'react-icons/md';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { H3 } from '../../../components/base/headings';
import { phoneMask } from '../../../utils/masks';
import InputText from '../../../components/base/v2/inputs/text';

import * as I from './interfaces';

export default function Contacts({
  isDisabled,
}: I.ContactsProps): React.ReactElement {
  const { register, setValue, control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  return (
    <Stack direction="column" spacing="md">
      <H3>
        Dados de Contato
        <Button
          variant="ghost"
          isDisabled={isDisabled}
          fontSize="1.3rem"
          maxW="25px"
          maxH="25px"
          onClick={(): void =>
            append({
              name: '',
              email: '',
              phone: '',
            })
          }
        >
          <Icon as={MdOutlineAddCircleOutline} />
        </Button>
      </H3>

      {fields.map((item, index) => (
        <Stack
          key={`client_contact_${item.id}`}
          direction="row"
          spacing="md"
          alignItems="end"
        >
          <InputText
            label="Nome"
            {...register(`contacts.${index}.name`)}
            isDisabled={isDisabled}
            formcontrol={{
              style: { width: '25%' },
            }}
          />

          <InputText
            label="Telefone"
            {...register(`contacts.${index}.phone`)}
            isDisabled={isDisabled}
            onChange={(e) =>
              setValue(
                `contacts.${index}.phone`,
                phoneMask({ value: e.target.value })
              )
            }
            formcontrol={{
              style: { width: '25%' },
            }}
          />

          <InputText
            label="E-mail"
            {...register(`contacts.${index}.email`)}
            isDisabled={isDisabled}
            formcontrol={{
              style: { width: '25%' },
            }}
          />

          {!isDisabled && (
            <Button colorScheme="gray" onClick={(): void => remove(index)}>
              <Icon as={BsTrashFill} color="#404e4d" />
            </Button>
          )}
        </Stack>
      ))}
    </Stack>
  );
}
