import { PhoneModel } from '../../interfaces/order';
import {
  customDateTimeFormater,
  dateFormater,
  dateFullFormater,
  dateFullFormaterList,
} from '../formatDate';
import { cepMask, phoneMask } from '../masks';
import { CheckPhoneProps } from './interface';

export const checkString = (value: string | undefined): string => {
  if (value) {
    return value;
  }

  return 'Não informado';
};

export const checkBoolean = (value: boolean | undefined): string => {
  if (value === undefined) {
    return 'Não informado';
  }

  if (value) {
    return 'Sim';
  }

  return 'Não';
};

export const checkNumber = (value: number | undefined): number => {
  if (value) {
    return value;
  }
  return 0;
};

export const checkDateCustom = (value: string | undefined): string => {
  if (value) {
    return customDateTimeFormater(new Date(value));
  }
  return 'Não informado';
};

export const checkDate = (value: string | undefined): string => {
  if (value) {
    return dateFormater(new Date(value));
  }
  return 'Não informado';
};

export const checkFullDate = (value: string | undefined): string => {
  if (value) {
    return dateFullFormater(new Date(value));
  }
  return 'Não informado';
};

export const checkFullDateList = (value: string | undefined): string => {
  if (value) {
    return dateFullFormaterList(new Date(value));
  }
  return 'Não informado';
};

export const checkStatus = (value: string | undefined): string => {
  if (value) {
    return value === 'Verified' ? 'Sim' : 'Não';
  }

  return 'Não informado';
};

export const checkPostalCode = (value: string | undefined): string => {
  if (value) {
    return cepMask(value);
  }

  return 'Não informado';
};

export const checkComplement = (value: string | undefined): string => {
  if (value) {
    return ` - ${value}`;
  }

  return '';
};

export const checkPhone = ({
  value,
  removeHyphen,
  removeFormat,
}: CheckPhoneProps): string => {
  if (value) {
    if (removeFormat) {
      return `+${value}`;
    }
    return phoneMask({ value, removeHyphen });
  }

  return 'Não informado';
};

export const handlePhones = (phones?: PhoneModel[]): string[] => {
  if (phones && phones.length > 0) {
    return phones.map((phone) => checkPhone({ value: phone.full_number }));
  }
  return ['Não informado'];
};

export const checkBoleto = (value: string | undefined): string => {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d{5})/, '$1.$2 ')
      .replace(/(\d{5})(\d{6})/, '$1.$2 ')
      .replace(/(\d{5})(\d{6})/, '$1.$2 ')
      .replace(/(\d)(\d{14})/, '$1 $2');
  }
  return 'Não informado';
};

export const checkEmptyObject = (obj: Object) => Object.keys(obj).length === 0;
