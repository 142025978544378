import React from 'react';
import { Grid, GridItem, Stack } from '@chakra-ui/react';
import Stat from '../../../../components/stat';
import Badge from '../../../../components/badge';
import { formatDate } from '../../../../utils/formatDate';

import * as I from '../../interfaces';

const InformationBase: React.FC<I.InformationBaseProps> = ({
  dataTestId,
  identifierPrefix,
  model,
}) => {
  return (
    <Stack
      data-testid={dataTestId}
      spacing="medium"
      background="white"
      direction="row"
      p="medium"
      borderRadius="extra-large"
      mb="16px"
    >
      <Grid templateColumns="repeat(10, 1fr)" gap="medium" width="100%">
        <GridItem colSpan={2}>
          <Stat
            title="Nome"
            text={model.description}
            identifier={`${identifierPrefix}_description`}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Stat
            title="URI"
            text={model.external_uri}
            identifier={`${identifierPrefix}_external_uri`}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Stat
            title="Backend"
            text={model.backend}
            identifier={`${identifierPrefix}_backend`}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Stat
            title="Última modificação"
            text={formatDate(model.modified_at)}
            identifier={`${identifierPrefix}_modified-at`}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Stat
            title="Modificado por"
            text={model.modified_by}
            identifier={`${identifierPrefix}_modified-by`}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Stat title="Status" identifier={`${identifierPrefix}_status`}>
            <Badge
              label={model.status === 'enabled' ? 'Ativo' : 'Inativo'}
              schema={model.status === 'enabled' ? 'green' : 'red'}
              transparent
            />
          </Stat>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default InformationBase;
