/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { FormLabel, InputGroup } from '@chakra-ui/react';
import { Controller, useWatch } from 'react-hook-form';
import Select from 'react-select';
import FormControl from './formControl';
import { customStyles, Option, Placeholder } from './utils';

import * as I from './interfaces';

const MultiSelect: React.FC<I.InputMultiSelectProps> = ({
  methods,
  label,
  name,
  options,
  formcontrol,
}) => {
  const { control } = methods;
  const selectValue = useWatch({
    control,
    name,
  });

  const handleValue = (formValue: string[]) => {
    if (formValue) {
      return formValue.map((item: string) => {
        if (typeof item === 'string') {
          return {
            value: item,
            label: item,
          };
        }
        return item;
      });
    }

    return [];
  };

  const [inputValue, setInputValue] = React.useState<any>();

  const handleValueToForm = (selectedValue: any) => {
    return selectedValue.map((item: any) => item.value);
  };

  useEffect(() => {
    setInputValue(handleValue(selectValue));
  }, [selectValue]);

  if (!inputValue && methods) {
    return <></>;
  }

  return (
    <FormControl {...formcontrol} style={{ width: '100%' }}>
      <InputGroup>
        <Controller
          control={control}
          name={name}
          render={({
            field: { onChange, value, name: inputName, ref: inputRef },
          }) => (
            <Select
              isMulti
              options={options}
              onChange={(newValue: any) =>
                onChange(handleValueToForm(newValue))
              }
              components={{ Option, Placeholder }}
              value={handleValue(value)}
              name={inputName}
              ref={inputRef}
              styles={customStyles}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isClearable={false}
              placeholder=" "
            />
          )}
        />
        <FormLabel htmlFor={name} className="active">
          {label}
        </FormLabel>
      </InputGroup>
    </FormControl>
  );
};

export default MultiSelect;
