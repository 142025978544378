/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { Query } from '@material-table/core';
import { AxiosResponse } from 'axios';
import { getUUID } from '../../../services/storage';
import {
  RetentionModel,
  RetentionFlowModel,
  CreateRetentionType,
  UpdateRetentionType,
  RetentionFlowResponseModel,
} from '../../../interfaces/retentionFlow';
import { trexApi } from '../../../services/api';
import checkValid from '../../../utils/checkValid';

export const useRetentionFlow = () => {
  const UUID = getUUID();
  const prefix = '/retention-flows';
  const headers = {
    ...(UUID && { 'X-SetID': UUID }),
  };

  const getRetentionFlow = async (
    query: Query<RetentionModel>
  ): Promise<AxiosResponse<RetentionFlowModel>> => {
    try {
      const response = await trexApi.get<RetentionFlowModel>(prefix, {
        params: {
          page: query.page + 1,
          'page-size': query.pageSize,
          'sort-by': query.orderBy?.field
            ? `${query.orderBy.field.toString()},${query.orderDirection}`
            : '',
          search: checkValid(query.search) || '',
        },
        ...(UUID && { headers }),
      });
      return response;
    } catch (err: any) {
      return err;
    }
  };

  const getRetentionFlowById = async (id: string) => {
    try {
      const response = await trexApi.get(`${prefix}/${id}`, {
        ...(UUID && { headers }),
      });

      return response;
    } catch (err: any) {
      return err;
    }
  };

  const getRetentionFlowRules = async () => {
    try {
      const response = await trexApi.get(`${prefix}/rules`, {
        params: { 'page-size': 100 },
        headers: {
          ...(UUID && { headers }),
        },
      });

      return response;
    } catch (err: any) {
      return err;
    }
  };

  const createRetentionFlow = async ({ data }: CreateRetentionType) => {
    try {
      const response: AxiosResponse<RetentionFlowResponseModel> =
        await trexApi.post(prefix, data, {
          headers: {
            ...(UUID && { headers }),
          },
        });

      return response;
    } catch (err: any) {
      return err;
    }
  };

  const updateRetentionFlow = async ({
    id,
    data,
    version,
  }: UpdateRetentionType) => {
    try {
      const response: AxiosResponse<RetentionFlowResponseModel> =
        await trexApi.put(`${prefix}/${id}`, data, {
          headers: {
            'if-match': version,
            ...(UUID && { headers }),
          },
        });

      return response;
    } catch (err: any) {
      return err;
    }
  };

  return {
    createRetentionFlow,
    getRetentionFlow,
    getRetentionFlowById,
    getRetentionFlowRules,
    updateRetentionFlow,
  };
};
