import React from 'react';
import { Box, Image, Stack } from '@chakra-ui/react';
import Stone from '../../../../assets/logos/stone.svg';
import Profile from './profile';
import GoBack from './goBack';
import MenuIcon from '../../../../assets/icons/menuIcon.svg';

import * as I from './interface';
import * as S from './styles';

const Header = ({
  link,
  handleChangeIcons,
}: I.MenuProps): React.ReactElement => {
  return (
    <Stack
      height="extra-huge"
      background="v2.green.300"
      align="center"
      paddingX="larger"
      direction="row"
      position="fixed"
      width="100%"
      zIndex="sticky"
    >
      <Box w="50%">
        {link ? (
          <GoBack link={link} />
        ) : (
          handleChangeIcons && (
            <S.HamburguerButton onClick={(): void => handleChangeIcons()}>
              <Image alt="menuIcon" src={MenuIcon} width="30px" height="30px" />
            </S.HamburguerButton>
          )
        )}
      </Box>
      <Image alt="logo stone" src={Stone} height="1.563rem" width="5.938rem" />
      <Box w="50%" textAlign="right" display="flex" justifyContent="flex-end">
        <Profile />
      </Box>
    </Stack>
  );
};

export default Header;
