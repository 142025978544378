/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React, { ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import Select, {
  components,
  OptionProps,
  PlaceholderProps,
} from 'react-select';
import { Controller, useWatch } from 'react-hook-form';
import { Checkbox, FormLabel, InputGroup } from '@chakra-ui/react';

import * as I from './interfaces';

import FormControl from './formControl';
import useDebounce from '../../../../hooks/debounce';
import { customStyles } from './utils';

export const Option = (props: OptionProps): ReactElement => {
  const { isSelected, label: labelOption } = props;
  return (
    <components.Option {...props}>
      <Checkbox isChecked={isSelected} isReadOnly>
        {labelOption}
      </Checkbox>
    </components.Option>
  );
};

const Placeholder = (props: PlaceholderProps) => {
  return <components.Placeholder {...props} />;
};

const UUID_REGEX =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

const InputSearchSelect = ({
  formcontrol,
  label,
  name,
  control,
  fetchByAPI,
  isClearable = false,
  isMulti = false,
  initialOptions = [],
  defaultValue: defaultValueProps,
  dataTestId,
  marginTop = '0px',
  width,
  ...rest
}: I.InputSearchSelectProps): ReactElement => {
  const selectValue = useWatch({
    control,
    name,
  });
  const [options, setOptions] = useState(initialOptions);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 500);
  const [uuidsBeingFetched, setUuidsBeingFetched] = useState<string[]>([]);

  const transformValue = (fieldValue: any) => {
    if (isMulti) {
      if (Array.isArray(fieldValue)) {
        return fieldValue
          .map((value: any) => {
            const optionFound = options?.find(
              (option) => option.value === value
            );

            if (optionFound) {
              return optionFound;
            }

            if (typeof value === 'string') {
              if (UUID_REGEX.test(value)) {
                const existingOption = options?.find(
                  (option) => option.value === value
                );

                if (
                  !existingOption &&
                  fetchByAPI &&
                  !uuidsBeingFetched.includes(value)
                ) {
                  setUuidsBeingFetched((prevUuids) => [...prevUuids, value]);

                  fetchByAPI
                    .getByID({ id: value })
                    .then(({ data }: any) => {
                      const newOption = {
                        value,
                        label: data.name,
                      };

                      if (!options.some((opt) => opt.value === value)) {
                        setOptions((prevOptions) => [
                          ...prevOptions,
                          newOption,
                        ]);
                      }

                      setUuidsBeingFetched((prevUuids) =>
                        prevUuids.filter((uuid) => uuid !== value)
                      );
                    })
                    .catch((error: any) => {
                      toast.error(
                        'Ocorreu um erro durante a obtenção do nome para o UUID:',
                        error
                      );
                      setUuidsBeingFetched((prevUuids) =>
                        prevUuids.filter((uuid) => uuid !== value)
                      );
                    });
                }
                return existingOption ?? { label: 'carregando...', value };
              }

              return { label: value, value };
            }
            return null;
          })
          .filter(Boolean);
      }

      return [];
    }

    if (typeof fieldValue === 'string') {
      return { label: fieldValue, value: fieldValue };
    }

    return options?.find((option) => option.value === fieldValue) ?? null;
  };

  // tratar as options quando tiver api
  useEffect(() => {
    if (defaultValueProps) {
      return;
    }

    if (debouncedInputValue && fetchByAPI) {
      fetchByAPI
        .getOptions({ page: 0, search: debouncedInputValue })
        .then(({ data }: any) => {
          const transformedOptions = data.items.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));

          setOptions(transformedOptions);
        })
        .catch((error: any) => {
          toast.error('Ocorreu um erro durante a obtenção das opções:', error);
        });
    }
  }, [debouncedInputValue, fetchByAPI, defaultValueProps]);

  return (
    <FormControl {...formcontrol} variant="none">
      <InputGroup data-testid={dataTestId} mt={marginTop}>
        <FormLabel htmlFor={name} variant="floating" className="active">
          {label}
        </FormLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={selectValue ?? defaultValueProps}
          render={({ field }) => {
            const SelectComponent =
              isClearable && !fetchByAPI ? CreatableSelect : Select;
            const transformedValue = transformValue(field?.value);

            return (
              <div style={{ minWidth: 260, width }}>
                <SelectComponent
                  {...field}
                  {...rest}
                  isMulti={isMulti}
                  placeholder=" "
                  isClearable={isClearable}
                  components={isMulti ? { Option, Placeholder } : {}}
                  options={options}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  onInputChange={setInputValue}
                  data-testid="search-select-input"
                  id={name}
                  styles={customStyles}
                  ref={field.ref}
                  value={transformedValue}
                  onChange={(selectedOption: any) =>
                    isMulti
                      ? field.onChange(
                          selectedOption.map((option: any) => option.value)
                        )
                      : field.onChange(selectedOption.value)
                  }
                  onCreateOption={(newOption) => {
                    const newOptionObject = {
                      label: newOption,
                      value: newOption,
                    };

                    setOptions((currentOptions) => [
                      ...currentOptions,
                      newOptionObject,
                    ]);

                    if (isMulti) {
                      const newValue = [...(field.value || []), newOption];
                      field.onChange(newValue);
                    } else {
                      field.onChange(newOption);
                    }
                  }}
                />
              </div>
            );
          }}
        />
      </InputGroup>
    </FormControl>
  );
};

export default InputSearchSelect;
