/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiLink2 } from 'react-icons/fi';
import { Query, QueryResult } from '@material-table/core';
import { AbsoluteCenter } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import useModels from '../../../../hooks/api/models';
import useClients from '../../../../hooks/api/clients';

import GenericForm from '../../../../components/base/v2/genericForm';
import LoadingSpinner from '../../../../components/loadingSpinner';
import {
  nameAndIDColumn,
  documentColumn,
  ExternalIdColumn,
  IdColumn,
} from '../../../../components/base/v2/table/columns';
import DefaultTable from '../../../../components/base/v2/table';
import { ClientModel } from '../../../../interfaces/client';
import { ModelsModel } from '../../../../interfaces/models';
import { formatConstants } from '../../../../utils/formatConstants';
import { constants } from '../../../../constants';

const ModelsUpdateInfo = () => {
  const { getOneModel } = useModels();
  const { getAllClients, postClientsBonds, deleteClientsModel } = useClients();
  const tableRef = useRef<any>();

  const [model, setModel] = useState<ModelsModel>();
  const [loading, setLoading] = useState(true);

  const { id } = useParams<{ id: string }>();

  const loadInfo = useCallback(async (): Promise<void> => {
    const response = await getOneModel(id);
    setModel(response.data);
    setLoading(false);
  }, [id]);

  const reloadTable = useCallback(() => {
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange();
    }
  }, []);

  const onButtonClick = () => {
    if (tableRef.current) {
      tableRef.current.dataManager?.changeRowEditing();
      tableRef.current.setState({
        ...tableRef.current.dataManager?.getRenderState(),
        showAddRow: true,
      });
    }
  };

  useEffect(() => {
    loadInfo();
  }, []);

  const loadTable = async (
    query: Query<ClientModel>
  ): Promise<QueryResult<ClientModel>> => {
    try {
      const result = await getAllClients({ data: { model }, query });
      if (result.data.items) {
        const total =
          result.data.total_of_items > 9999
            ? 10000
            : result.data.total_of_items;
        return {
          data: result.data.items,
          page: result.data.page - 1,
          totalCount: total,
        };
      }
      return {
        data: [],
        page: result.data.page - 1,
        totalCount: result.data.total_of_items,
      };
    } catch (err: unknown) {
      if (err instanceof Error) {
        const message = formatConstants(
          constants.errorToLoadContent,
          'Clientes'
        );
        toast.error(`${message}: ${err.message}`);
      }
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  const clientTableEdit = {
    onRowAdd: async (data: { name: ClientModel }) => {
      try {
        const client = data.name;
        if (client.id === undefined) {
          toast.warn(constants.errorNecessaryClient);
          return;
        }
        await postClientsBonds({
          url: `clients/${client.id}/models/${model?.id}`,
          version: client.version,
        });
        const message = formatConstants(constants.linkWithSuccess, 'Cliente');
        toast.success(message);
        setTimeout(reloadTable, 750);
      } catch (err: unknown) {
        if (err instanceof Error) {
          const message = formatConstants(
            constants.errorToLinkWithReason,
            'cliente'
          );
          toast.error(`${message}: ${err.message}`);
        }
      }
    },

    onRowDelete: async (client: ClientModel) => {
      try {
        await deleteClientsModel({
          id: client.id,
          scopeID: id,
          version: client.version,
        });
        const message = formatConstants(constants.unlinkWithSuccess, 'Cliente');
        toast.success(message);
        setTimeout(reloadTable, 750);
      } catch (err: unknown) {
        if (err instanceof Error) {
          const message = formatConstants(
            constants.errorToUnlinkWithReason,
            'cliente'
          );
          toast.error(`${message}: ${err.message}`);
        }
      }
    },
  };

  const tableColumns = [
    nameAndIDColumn('/client/info'),
    IdColumn,
    ExternalIdColumn,
    documentColumn,
  ];

  if (loading) {
    return (
      <AbsoluteCenter>
        <LoadingSpinner />
      </AbsoluteCenter>
    );
  }

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Modelo', href: '/models' },
        {
          label: `${model?.description}`,
          href: `/models/info/${model?.id}`,
        },
        { label: 'Clientes' },
      ]}
      title={`${model?.description}`}
      handleAction={onButtonClick}
      actionText="Vincular novo cliente"
      buttonIcon={FiLink2}
      isDisabled
      showBackButton
    >
      <DefaultTable
        columns={tableColumns}
        data={loadTable}
        searchLabel="Busque por nome ou ID"
        accessURL="/client/info"
        editable={clientTableEdit}
        tableRef={tableRef}
      />
    </GenericForm>
  );
};

export default ModelsUpdateInfo;
