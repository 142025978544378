import React from 'react';
import { toast } from 'react-toastify';
import { Route, Redirect } from 'react-router-dom';
import { AuthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import checkRoles from '../utils/checkRoles';
import generateUUID from '../utils/generateUUID';
import { SETID } from '../services/storage';

import * as I from '../interfaces/routes';

const PrivateRoute = ({
  component: Component,
  roles,
  ...rest
}: I.PrivateRoute) => {
  const hasRole = checkRoles({ roles });
  const UUID = generateUUID();
  const isAuthenticated = useIsAuthenticated();
  localStorage.setItem(SETID, UUID);

  if (roles && !hasRole && isAuthenticated) {
    toast.error(`Você não possui acesso a este recurso`);
    return <Redirect to="/" />;
  }

  return (
    <AuthenticatedTemplate>
      <Route
        {...rest}
        render={(routeProps) => <Component {...routeProps} {...rest} />}
      />
    </AuthenticatedTemplate>
  );
};

PrivateRoute.defaultProps = {
  exact: true,
};

export default PrivateRoute;
