import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { StyledToastContainer } from './styles/toast';
import { InterceptorProvider } from './context/interceptor';
import { RulesFlowProvider } from './providers/rulesFlow';
import { RulesModelsProvider } from './providers/models';
import { msalConfig } from './auth/msalConfig';
import Routes from './routes';
import Menu from './components/base/v2/menu';

import { theme, styledTheme } from './styles/theme';
import GlobalStyle from './styles/globals';

const App = (): React.ReactElement => {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <ChakraProvider theme={theme}>
        <StyledThemeProvider theme={styledTheme}>
          <InterceptorProvider>
            <BrowserRouter>
              <RulesFlowProvider>
                <RulesModelsProvider>
                  <Menu />
                  <Routes />
                  <GlobalStyle />
                  <StyledToastContainer autoClose={5000} />
                </RulesModelsProvider>
              </RulesFlowProvider>
            </BrowserRouter>
          </InterceptorProvider>
        </StyledThemeProvider>
      </ChakraProvider>
    </MsalProvider>
  );
};

export default App;
