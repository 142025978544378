/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, MenuItem, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { FaRegClone } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DoubleCheckModal from '../../doubleCheckModal';
import InputText from '../../inputs/text';
import InputSelect from '../../inputs/select';
import LoadingSpinner from '../../../../loadingSpinner';
import useBlocks from '../../../../../hooks/api/blocks';
import { handleFormDataToModel } from '../../../../../pages/rulesFlow/utils';
import { BlockCloneModalType } from './interface';
import { useRulesFlow } from '../../../../../providers/rulesFlow';

const CloneBlockModal: React.FC<BlockCloneModalType> = ({ block }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cloneData, setCloneData] = useState({
    description: '',
    allowReview: false,
  });

  const { rulesData } = useRulesFlow();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { createBlock } = useBlocks();
  const history = useHistory();

  const onClone = async (): Promise<void> => {
    setIsLoading(true);
    const data = {
      allow_review: cloneData.allowReview,
      description: cloneData.description,
      nodes: block.nodes,
    };

    try {
      const res = await createBlock({
        data: handleFormDataToModel(rulesData.rules, data as any),
      });

      if (res && [200, 201, 204].includes(res.request.status)) {
        toast.success('Bloco clonado com sucesso!');
        history.push(`/blocks/info/${res.data.id}`);
        window.location.reload();
      } else {
        const { reason } = JSON.parse(res.request.response);
        toast.error(`Não foi possível clonar o bloco: ${reason}`);
      }
    } catch (error) {
      toast.error(
        'Não foi possível clonar o bloco. Tente novamente mais tarde.'
      );
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  const handleDescription = (e: React.ChangeEvent<{ value: string }>) => {
    setCloneData({ ...cloneData, description: e.target.value });
  };

  const handleAllowReview = (e: React.ChangeEvent<{ value: string }>) => {
    setCloneData({
      ...cloneData,
      allowReview: e.target.value === 'true',
    });
  };

  if (!block) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <MenuItem icon={<Icon as={FaRegClone} />} onClick={onOpen}>
        Clonar bloco
      </MenuItem>

      <DoubleCheckModal
        title="Clonar bloco"
        description={`Ao clonar o bloco ${block?.description}, é importante lembrar que quaisquer alterações não salvas não serão incluídas na cópia.`}
        isOpen={isOpen}
        onClose={onClose}
        modal={{ size: 'xl' }}
        primaryButton={{
          colorScheme: 'v2.brand',
          text: 'Clonar bloco',
          action: onClone,
        }}
        isLoading={isLoading}
      >
        <Stack
          spacing="medium"
          background="white"
          p="medium"
          borderRadius="extra-large"
        >
          <Text fontWeight="bold">Informações do bloco</Text>
          <Stack direction="row" spacing="medium">
            <InputText
              label="Bloco"
              value={cloneData.description}
              onChange={handleDescription}
              formcontrol={{ isRequired: true }}
            />

            <InputSelect
              label="Permitir revisão"
              options={[
                { option: 'Sim', value: 'true' },
                { option: 'Não', value: 'false' },
              ]}
              value={cloneData.allowReview.toString()}
              onChange={handleAllowReview}
              formcontrol={{ isRequired: true }}
            />
          </Stack>
        </Stack>
      </DoubleCheckModal>
    </>
  );
};

export default CloneBlockModal;
