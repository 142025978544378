import { AutoCompleteDescription } from '../../../interfaces/components/base/autocomplete';

export const getFlowsSuggestions = (
  suggestion: string,
  list: AutoCompleteDescription[] = []
): Array<{ id: string; description: string }> => {
  if (!Array.isArray(list)) {
    return [];
  }
  return list.filter((company) =>
    `${company.description.toLowerCase()} (ID: ${company.id.toLowerCase()})`.includes(
      suggestion.toLowerCase().trim()
    )
  );
};
