/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';

import { FiLink2 } from 'react-icons/fi';
import { nameAndIDColumn } from './tableConfig';

import DefaultTable from '../../../../components/base/v2/table';
import { ClientModel } from '../../../../interfaces/client';
import useActionLists from '../../../../hooks/api/actionLists';
import useClients from '../../../../hooks/api/clients';
import GenericForm from '../../../../components/base/v2/genericForm';
import useQuery from '../../../../hooks/queryParams';
import { IdColumn } from '../../../../components/base/v2/table/columns';
import LoadingSpinner from '../../../../components/loadingSpinner';
import * as S from '../styles';

export default function ClientUpdateAction(): React.ReactElement {
  const { getOneActionList } = useActionLists();
  const { postClientsActionList, getOneClient } = useClients();

  const [client, setClient] = useState<ClientModel | null>(null);
  const [tableData, setTableData] = useState<object[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const tableRef = useRef<any>();
  const query = useQuery();
  const clientID = query.get('id') ?? undefined;
  const columns = [nameAndIDColumn('/actions-list/info'), IdColumn];

  const onButtonClick = () => {
    if (tableRef.current) {
      tableRef.current.dataManager?.changeRowEditing();
      tableRef.current.setState({
        ...tableRef.current.dataManager?.getRenderState(),
        showAddRow: true,
      });
    }
  };

  const handleData = async (data: ClientModel) => {
    if (data.action_lists) {
      const aux: object[] = [];
      await new Promise<void>((resolve) => {
        data.action_lists?.forEach((id: string) => {
          getOneActionList(id).then((result) => {
            aux.push(result.data);
            resolve();
          });
        });
      });
      setTableData(aux);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const loadInfo = useCallback(async (): Promise<ClientModel> => {
    setIsLoading(true);

    const response = await getOneClient({ id: clientID });
    setClient(response.data);

    return response.data;
  }, []);

  useEffect(() => {
    loadInfo().then((infoData) => handleData(infoData));
  }, []);

  const clientEditable = {
    onRowAdd: (data: ClientModel): Promise<void> =>
      new Promise((resolve) => {
        setTimeout(() => {
          setIsLoading(true);
          const actions = data.name;
          postClientsActionList({
            id: client?.id,
            actions,
            version: client?.version,
          })
            .then((res) => {
              if ([200, 201, 204].includes(res.request.status)) {
                toast.success('Vínculo realizado com sucesso!');
                setTimeout(() => {
                  loadInfo().then((infoData) => handleData(infoData));
                  resolve();
                }, 1000);
                return;
              }
              const { reason } = JSON.parse(res.request.response);
              toast.warn(`Não foi possível realizar o vínculo. ${reason}`);
              setIsLoading(false);
            })
            .catch(() => {
              toast.warn('Não foi possível realizar o vínculo.');
              setTimeout(() => {
                loadInfo().then((infoData) => handleData(infoData));
                resolve();
              }, 1000);
            });

          resolve();
        }, 1000);
      }),
  };

  if (!client) {
    return (
      <S.LoadingContainer>
        <LoadingSpinner />
      </S.LoadingContainer>
    );
  }

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Cliente', href: '/client' },
        { label: `${client.name}`, href: `/client/info?id=${client.id}` },
        { label: 'Lista' },
      ]}
      title={client.name}
      handleAction={onButtonClick}
      actionText="Vincular nova lista"
      buttonIcon={FiLink2}
      isDisabled
      showBackButton
    >
      <DefaultTable
        columns={columns}
        data={tableData}
        searchLabel="Busque por nome ou ID"
        accessURL="/actions-list/info"
        editable={clientEditable}
        tableRef={tableRef}
        isLoading={isLoading}
      />
    </GenericForm>
  );
}
