import * as Yup from 'yup';
import {
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import * as I from '../../../interfaces/flow';

export const validateSchema = (isRetention?: boolean, isBlock?: boolean) => {
  return Yup.object().shape({
    ...(!isRetention &&
      !isBlock && {
        title: Yup.string().required('Título é obrigatório'),
        message: Yup.string().required('Descrição é obrigatório'),
      }),
    description: Yup.string().required('Nome do Fluxo é obrigatório'),
    allow_review: Yup.boolean(),
    nodes: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string().required('Nome do Nó é obrigatório'),
          operator: Yup.string().required('Tipo é obrigatório'),
          error_policy: Yup.string().required('Política de erro é obrigatório'),
          terms: Yup.array().of(
            Yup.object().shape({
              complement: Yup.boolean().required('Complemento é obrigatório'),
              error_policy: Yup.string().required(
                'Política de erro é obrigatório'
              ),
              rule: Yup.object().shape({
                name: Yup.string().required('Regra é obrigatório'),
              }),
            })
          ),
        })
      )
      .min(1, 'Deve haver pelo menos uma nó.'),
  });
};

export const appendNodeObject = {
  title: '',
  operator: '',
  action: 'approve',
  error_policy: '',
  terms: [
    {
      complement: false,
      error_policy: '',
      rule: {
        name: '',
      },
    },
  ],
};

export const typeOptions = [
  { option: 'E', value: 'AND' },
  { option: 'OU', value: 'OR' },
];

export const policyOptions = [
  { option: 'Ignorar', value: 'ignore' },
  { option: 'Falhar', value: 'fail' },
];

export const cloneNodes = (
  clonedNodes: I.NodeModel[],
  appendFunction: (node: I.NodeModel, index: number) => Promise<void>
) => {
  return clonedNodes.reduce(async (previousPromise, node, index) => {
    await previousPromise;
    return appendFunction(node, index);
  }, Promise.resolve());
};

export const getStyle = (
  snapshot: DraggableStateSnapshot,
  dragStyle?: DraggingStyle | NotDraggingStyle
) => {
  if (!snapshot.isDropAnimating) {
    return dragStyle;
  }
  return {
    ...dragStyle,
    transitionDuration: `0.05s`,
  };
};

export const actionTranslations: { [key: string]: string } = {
  approve: 'Aprovar',
  reject: 'Rejeitar',
  review: 'Revisar',
  suspend: 'Suspender',
  withhold: 'Reter',
  'dont-withhold': 'Não reter',
};

export const actionColors: {
  [key: string]: 'green' | 'red' | 'orange' | 'blue';
} = {
  approve: 'green',
  reject: 'red',
  review: 'orange',
  suspend: 'blue',
  withhold: 'red',
};
