import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { AbsoluteCenter } from '@chakra-ui/react';
import CheckRoles from '../utils/checkRoles';
import PrivateRoute from './privateRoute';
import ConstantRoutes from './routesArray';
import LoadingSpinner from '../components/loadingSpinner';
import ClientsList from '../pages/clients/list';

const OrdersList = lazy(() => import('../pages/orders/list'));
const NotAllowed = lazy(() => import('../pages/notAllowed'));

const Routes = (): React.ReactElement => {
  const handleComponentByRole = () => {
    if (CheckRoles({ roles: [''] })) {
      return NotAllowed;
    }
    if (CheckRoles({ roles: ['view_analysis'] })) {
      return OrdersList;
    }
    return ClientsList;
  };
  const Component = handleComponentByRole();

  return (
    <Suspense
      fallback={
        <AbsoluteCenter>
          <LoadingSpinner />
        </AbsoluteCenter>
      }
    >
      <Switch>
        <PrivateRoute
          roles={['view_clients', 'view_analysis']}
          path="/"
          exact
          component={Component}
        />

        {ConstantRoutes.map((route) => (
          <PrivateRoute
            key={route.path}
            roles={route.roles}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
    </Suspense>
  );
};

export default Routes;
