import React, { useState } from 'react';
import { Alert, AlertIcon, Checkbox, Stack } from '@chakra-ui/react';
import RulesFlow from '../../../../../sections/client/form/bonds/rulesFlow';
import DoubleCheckModal from '../../doubleCheckModal';
import { useRulesFlow } from '../../../../../providers/rulesFlow';

import * as I from './interfaces';

const CloneNodeModal = ({
  onClose,
  isOpen,
  action,
  flow,
  setFlow,
}: I.CloneFlowModalProps) => {
  const { dataContext } = useRulesFlow();
  const [isDiferentFlow, setIsDiferentFlow] = useState(false);

  if (!isDiferentFlow) {
    setFlow(`${dataContext?.id}`);
  }

  return (
    <DoubleCheckModal
      title="Clonar nós de regras"
      description="Ao clonar um ou mais nós, é importante lembrar que a clonagem só ira ser salva ao clicar o botão de Publicar Alterações."
      isOpen={isOpen}
      onClose={onClose}
      modal={{ size: 'xl' }}
      primaryButton={{
        colorScheme: 'v2.brand',
        text: 'Clonar nó(s)',
        action,
      }}
    >
      <Stack>
        <Checkbox
          isChecked={isDiferentFlow}
          onChange={() => setIsDiferentFlow(!isDiferentFlow)}
          colorScheme="v2.brand"
          my={5}
        >
          Deseja clonar o(s) nó(s) para um fluxo diferente?
        </Checkbox>
        {isDiferentFlow && (
          <>
            <Stack direction="row">
              <RulesFlow
                isDisabled={false}
                setValue={setFlow}
                value={flow}
                label="Selecione o Fluxo"
                spacing="sm"
              />
            </Stack>
            <Stack direction="row">
              <Alert status="warning">
                <AlertIcon />
                Ao clicar em Clonar você será redirecionado para a página do
                fluxo selecionado.
              </Alert>
            </Stack>
          </>
        )}
      </Stack>
    </DoubleCheckModal>
  );
};

export default CloneNodeModal;
