import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Icon, Stack } from '@chakra-ui/react';
import { BiUnlink } from 'react-icons/bi';
import { FiExternalLink } from 'react-icons/fi';
import { H3 } from '../../../../components/base/headings';
import AutocompleteFlow from '../../../../components/base/autocompleteFlow';
import { handleExternalLink } from '../../../../utils/externalLink';

import * as I from '../interfaces';

const RetentionFlow = ({
  handleUnlinkFlow,
  isDisabled,
  value,
  setValue,
  label,
  spacing = 'md',
}: I.BondsProps): React.ReactElement => {
  const { register, control, setValue: setInputValue } = useFormContext();

  const retentionID = useWatch({
    control,
    name: 'retention_flow_id',
  });

  const handleSetValues = (val: string) => {
    setValue(val);
    setInputValue('retention_flow_id', val);
  };

  return (
    <Stack spacing={spacing}>
      <H3>{label ?? 'Fluxo de Retenção Vinculado'}</H3>
      <Stack direction="row" spacing={spacing} alignItems="center">
        <div
          className="default--input"
          style={{ flex: 1 }}
          data-testid="flows-input"
        >
          <AutocompleteFlow
            {...register('retention_flow_id')}
            action={handleSetValues}
            disabled={isDisabled}
            defaultValue={value}
            isRetention
          />
        </div>

        {retentionID && isDisabled && (
          <Button
            data-testid="external-link"
            type="button"
            onClick={() =>
              handleExternalLink(`/retention-flow/info/${retentionID}`)
            }
            colorScheme="gray"
            title="Acessar esse fluxo"
          >
            <Icon as={FiExternalLink} />
          </Button>
        )}

        {handleUnlinkFlow && (
          <Button
            type="button"
            isDisabled={isDisabled || !value}
            colorScheme="gray"
            onClick={(): void => {
              handleUnlinkFlow(retentionID);
            }}
          >
            <Icon as={BiUnlink} />
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default RetentionFlow;
