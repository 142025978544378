import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Icon,
  Stack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CgAddR } from 'react-icons/cg';
import { HiOutlineTrash } from 'react-icons/hi';
import Button from '../../base/v2/button';
import InputText from '../../base/v2/inputs/text';
import { phoneMask } from '../../../utils/masks';
import Stat from '../../stat';

import * as I from './interfaces';

const Contacts = ({
  partner,
  isDisabled,
}: I.PartnersInfo): React.ReactElement => {
  const methods = useFormContext();
  const {
    control,
    setValue,
    formState: { errors },
    register,
  } = methods;

  const {
    fields: contacts,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'contacts',
  });

  const handleRemoveContact = (index: number): void => {
    remove(index);
  };

  const handleAddContact = (): void => {
    append({ name: '', email: '', phone: '' });
  };

  const hasOnly = contacts.length <= 1;

  return (
    <Stack spacing="regular">
      <Stack spacing="smallest">
        {contacts.map((contact, index) => {
          const key = `partner_contact__${contact.id}-${index}`;
          return (
            <Stack
              key={key}
              background="white"
              borderRadius="extra-large"
              p="medium"
            >
              <Stack direction="row" spacing="regular">
                <Wrap spacing="regular">
                  {isDisabled ? (
                    <>
                      {partner?.contacts?.[index]?.name ? (
                        <WrapItem>
                          <Stat
                            title="Nome"
                            text={partner.contacts[index].name}
                            identifier={`form_contacts--${index}_name`}
                          />
                        </WrapItem>
                      ) : null}

                      {partner?.contacts?.[index]?.phone ? (
                        <WrapItem>
                          <Stat
                            title="Telefone"
                            text={phoneMask({
                              value: partner.contacts[index].phone,
                            })}
                            identifier={`form_contacts--${index}_phone`}
                          />
                        </WrapItem>
                      ) : null}

                      {partner?.contacts?.[index]?.email ? (
                        <WrapItem>
                          <Stat
                            title="E-mail"
                            text={partner.contacts?.[index]?.email}
                            identifier={`form_contacts--${index}_email`}
                          />
                        </WrapItem>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <WrapItem>
                        <InputText
                          data-testid={`form_contacts--${index}_name`}
                          label="Nome"
                          {...register(`contacts.${index}.name`)}
                          formcontrol={{
                            isRequired: true,
                            error: errors.contacts?.[index]?.name,
                          }}
                        />
                      </WrapItem>

                      <WrapItem>
                        <InputText
                          data-testid={`form_contacts--${index}_phone`}
                          label="Telefone"
                          {...register(`contacts.${index}.phone`)}
                          max="+99 (99) 999999999"
                          onChange={(e) =>
                            setValue(
                              `contacts.${index}.phone`,
                              phoneMask({ value: e.target.value })
                            )
                          }
                          formcontrol={{
                            error: errors.contacts?.[index]?.phone,
                          }}
                        />
                      </WrapItem>

                      <WrapItem>
                        <InputText
                          data-testid={`form_contacts--${index}_email`}
                          label="E-mail"
                          {...register(`contacts.${index}.email`)}
                          formcontrol={{
                            error: errors.contacts?.[index]?.email,
                          }}
                        />
                      </WrapItem>
                    </>
                  )}
                </Wrap>

                <Stack direction="row" marginLeft="auto !important">
                  <Button
                    data-testid={`form_contacts--${index}_removeItem`}
                    onClick={() => handleRemoveContact(index)}
                    isDisabled={isDisabled || hasOnly}
                  >
                    <Icon as={HiOutlineTrash} />
                  </Button>
                </Stack>
              </Stack>

              {errors?.contacts?.[index]?.message ? (
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>
                    {errors?.contacts?.[index]?.message}
                  </AlertDescription>
                </Alert>
              ) : null}
            </Stack>
          );
        })}

        {errors?.contacts?.message ? (
          <Alert status="error" m="regular">
            <AlertIcon />
            <AlertDescription>{errors.contacts.message}</AlertDescription>
          </Alert>
        ) : null}
      </Stack>

      {!isDisabled ? (
        <Stack
          direction="row"
          background="white"
          borderRadius="extra-large"
          p="medium"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            leftIcon={<Icon as={CgAddR} />}
            onClick={handleAddContact}
            isDisabled={isDisabled}
          >
            Adicionar contato
          </Button>
        </Stack>
      ) : null}
    </Stack>
  );
};

export default Contacts;
