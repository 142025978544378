import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';

import * as S from './styles';

export default function Profile(): React.ReactElement {
  const [user, setUser] = useState<AccountInfo>();
  const [isLoaded, setIsLoaded] = useState(false);
  const { push } = useHistory();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    setUser(accounts[0]);
    setIsLoaded(true);
  }, []);

  const logout = async (): Promise<void> => {
    if (user) {
      localStorage.clear();
      sessionStorage.clear();

      const logoutRequest = {
        account: instance.getAccountByHomeId(user.homeAccountId),
      };
      instance.logoutRedirect(logoutRequest);
    }
  };

  return (
    <>
      {isLoaded && (
        <Menu>
          <MenuButton
            height="extra-big"
            paddingX="small"
            borderRadius="large"
            background="rgba(255, 255, 255, 0.1);"
            color="white"
            _hover={{
              background: 'rgba(255, 255, 255, 0.1);',
            }}
            _disabled={{
              opacity: 1,
            }}
          >
            <S.TextContainer>
              Olá, {user?.name}
              <Avatar name={user?.name} h="big" w="big" marginLeft="smallest" />
            </S.TextContainer>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => push('/profile')}>Profile</MenuItem>
            <MenuItem onClick={() => logout()}>Logout</MenuItem>
          </MenuList>
        </Menu>
      )}
    </>
  );
}
