import React from 'react';
import { Grid, GridItem, Stack } from '@chakra-ui/react';

import { formatDate } from '../../../../utils/formatDate';
import Stat from '../../../stat';
import Badge from '../../../badge';

import * as I from './interfaces';

const Header = ({
  info,
  title,
  secondItem,
}: I.HeaderProps): React.ReactElement => {
  return (
    <Stack
      spacing="medium"
      background="white"
      direction="row"
      p="medium"
      borderRadius="extra-large"
      mb="24px"
      data-testid="header-container"
    >
      <Grid templateColumns="repeat(6, 1fr)" gap="medium" width="100%">
        <GridItem colSpan={2}>
          <Stat title={title} text={info.name} />
        </GridItem>

        {secondItem?.text && (
          <GridItem colSpan={1}>
            <Stat title={secondItem.title} text={secondItem.text} />
          </GridItem>
        )}

        <GridItem colSpan={1}>
          <Stat
            title="Última modificação"
            text={formatDate(info.modified_at)}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Stat title="Modificado por" text={info.modified_by} />
        </GridItem>
        {info.status && (
          <GridItem colSpan={1}>
            <Stat title="Status">
              <Badge
                label={info.status === 'enabled' ? 'Ativo' : 'Inativo'}
                schema={info.status === 'enabled' ? 'green' : 'red'}
                transparent
              />
            </Stat>
          </GridItem>
        )}
      </Grid>
    </Stack>
  );
};

export default Header;
