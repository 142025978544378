import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@chakra-ui/react';
import { H3 } from '../../../../base/headings';
import { GroupsFormProps } from '../../../../../interfaces/groups';
import { useGroups } from '../../../../../providers/groups';
import InputText from '../../../../base/v2/inputs/text';
import InputSwitch from '../../../../base/v2/inputs/switch';

export default function GroupsInformations({
  isDisabled,
}: GroupsFormProps): React.ReactElement {
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
  } = methods;

  const { dataContext } = useGroups();

  return (
    <Stack direction="column">
      <H3>Dados do Grupo</H3>

      <Stack direction="row" spacing="md" style={{ marginTop: '24px' }}>
        <InputText
          label="Nome"
          {...register('name')}
          formcontrol={{
            isDisabled,
            isRequired: true,
            error: errors.name,
            style: { width: '25%' },
          }}
          data-testid="name-input"
        />

        <InputText
          label="Descrição"
          {...register('description')}
          formcontrol={{
            isDisabled,
            error: errors.description,
            style: { width: '25%' },
          }}
          data-testid="description-input"
        />

        <InputSwitch
          label="Permitir revisão"
          mt="-2"
          {...register('allow_review')}
          formcontrol={{
            isDisabled: !!dataContext,
            error: errors.allow_review,
            style: { width: '25%' },
          }}
          data-testid="allow_review-checkbox"
        />
      </Stack>
    </Stack>
  );
}
