import React from 'react';
import { Stack, Textarea as ChakraTextarea } from '@chakra-ui/react';

import { useFormContext } from 'react-hook-form';
import * as I from './interfaces';

import { H3 } from '../../../components/base/headings';
import InputSelect from '../../../components/base/v2/inputs/select';

export default function Information({
  isDisabled,
}: I.InformationProps): React.ReactElement {
  const { register } = useFormContext();

  return (
    <Stack spacing="md" direction="column">
      <H3>Informações</H3>

      <InputSelect
        label="Tipo de revisão"
        options={[
          { option: 'Automática', value: 'auto' },
          { option: 'Manual', value: 'review' },
        ]}
        {...register('analysis')}
        isRequired
        formcontrol={{
          isDisabled,
        }}
      />

      <ChakraTextarea
        label="Informações do cliente"
        {...register('description')}
        name="description"
        isDisabled={isDisabled}
      />
    </Stack>
  );
}
