/* eslint-disable @typescript-eslint/no-explicit-any */
import * as I from './interfaces';

import { trexApi } from '../../../services/api';
import { getUUID } from '../../../services/storage';
import checkValid from '../../../utils/checkValid';
import { handleApiRequest } from '../utils';

const useBlocks = () => {
  const UUID = getUUID();
  const prefix = 'blocks';

  const createBlock = async ({ data }: Pick<I.BlocksAPIsProps, 'data'>) => {
    try {
      const response = await handleApiRequest(
        trexApi.post(prefix, data, {
          headers: { 'X-SetID': UUID },
        })
      );

      return response;
    } catch (err: any) {
      return err;
    }
  };

  const updateBlock = async ({ id, data, version }: I.BlocksAPIsProps) => {
    try {
      const response = await handleApiRequest(
        trexApi.put(`${prefix}/${id}`, data, {
          headers: {
            'if-match': version,
            'X-SetID': UUID,
          },
        })
      );

      return response;
    } catch (err: any) {
      return err;
    }
  };

  const getBlocks = async ({
    page,
    pageSize,
    orderBy,
    orderDirection,
    search,
  }: I.BlocksAPIsQueryType) => {
    try {
      const response = await handleApiRequest(
        trexApi.get(prefix, {
          params: {
            page: page + 1,
            'page-size': pageSize,
            'sort-by': orderBy?.field
              ? `${orderBy.field.toString()},${orderDirection}`
              : null,
            search: checkValid(search),
          },
          headers: {
            'X-SetID': UUID,
          },
        })
      );

      return response;
    } catch (err: any) {
      return err;
    }
  };

  const getOneBlock = async ({ id }: Pick<I.BlocksAPIsProps, 'id'>) => {
    try {
      const response = await handleApiRequest(
        trexApi.get(`${prefix}/${id}`, {
          headers: {
            'X-SetID': UUID,
          },
        })
      );

      return response;
    } catch (err: any) {
      return err;
    }
  };

  return {
    createBlock,
    updateBlock,
    getOneBlock,
    getBlocks,
  };
};
export default useBlocks;
