import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto ${({ theme }) => theme.spacing(3)}px;
`;

export const NodeContainer = styled.div`
  width: 100%;
  margin: 0 auto ${({ theme }) => theme.spacing(3)}px;
  border-radius: 0.625rem;
`;
