import React, { useEffect, useState } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { Box, Button, Icon, Stack, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { CgAddR } from 'react-icons/cg';
import { useRulesModels } from '../../../providers/models';
import { handleTagsFormToScopeModel } from '../../../components/bondTags/utils';
import validateSchema from '../../../validations/models';
import CloneModal from '../clone';
import BondTags from '../../../components/bondTags';
import useModels from '../../../hooks/api/models';
import { FormToScopeModelType } from '../../../components/bondTags/interfaces';
import FormFooter from '../../../components/base/v2/footer';
import LoadingSpinner from '../../../components/loadingSpinner';
import SubmenuModel from './submenu';
import Feature from './feature';

import * as S from './styles';
import * as I2 from '../interfaces';

const ModelsUpsertForm = ({
  model,
  handleSubmit,
  isOpenModal,
  onCloseModal,
}: I2.ModelFormProps): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const { updateModels } = useModels();
  const { getNewVersion, isDisabled, handleDisabled } = useRulesModels();
  const {
    onOpen: onDescartOpen,
    onClose: onDescartClose,
    isOpen: isDescartOpen,
  } = useDisclosure();

  const methods = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: model,
  });

  const {
    fields: features,
    append: appendFeatures,
    remove: removeFeatures,
    move: moveFeatures,
  } = useFieldArray({
    control: methods.control,
    name: 'features',
  });

  const handleDescart = async (): Promise<void> => {
    try {
      methods.reset(model);
      handleDisabled();
    } finally {
      onDescartClose();
    }
  };

  const handleTagSubmit = async ({
    data,
    type,
    scope,
  }: FormToScopeModelType) => {
    const formData = handleTagsFormToScopeModel({
      scope: model,
      data,
      type,
    });

    return updateModels({
      id: scope.id,
      data: formData,
      version: scope.version,
    }).finally(() => {
      getNewVersion();
    });
  };

  const handleOnDragEnd = ({ source, destination }: DropResult): void => {
    if (destination) {
      moveFeatures(source.index, destination.index);
    }
  };

  const handleAppendFeature = (): void => {
    appendFeatures({
      name: '',
    });
  };

  useEffect(() => {
    if (model) {
      setIsLoading(false);
      methods.reset(model);
    }
  }, [model]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {model.id && (
        <BondTags
          scope={model}
          scopeName="model"
          onSubmitScope={handleTagSubmit}
        />
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} id="model-form">
          <Box background="neutral.100" pb="regular">
            <SubmenuModel isEditing={!isDisabled} model={model} />
          </Box>

          <S.Container>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="model-dnd-droppable">
                {(dropProvided): React.ReactElement => (
                  <div
                    ref={dropProvided.innerRef}
                    {...dropProvided.droppableProps}
                  >
                    <Stack pb="regular" spacing="smallest">
                      {features.map((feature, index) => {
                        const key = `feature_${feature.id}_${index}`;
                        return (
                          <Feature
                            key={key}
                            featureIndex={index}
                            featuresLength={features.length}
                            feature={feature}
                            appendFeatures={appendFeatures}
                            removeFeatures={removeFeatures}
                            moveFeatures={moveFeatures}
                            features={features}
                          />
                        );
                      })}

                      {!isDisabled && (
                        <Stack
                          direction="row"
                          background="white"
                          borderRadius="extra-large"
                          p="medium"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Button
                            leftIcon={<Icon as={CgAddR} />}
                            onClick={handleAppendFeature}
                            isDisabled={isDisabled}
                          >
                            Adicionar feature
                          </Button>
                        </Stack>
                      )}
                    </Stack>

                    {dropProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </S.Container>

          {!isDisabled && (
            <FormFooter
              id={model?.id}
              label="Modelo"
              formName="model-form"
              onDescartOpen={onDescartOpen}
              isDescartOpen={isDescartOpen}
              onDescartClose={onDescartClose}
              handleDescart={handleDescart}
            />
          )}

          {isOpenModal && onCloseModal && (
            <CloneModal isOpenModal={isOpenModal} onClose={onCloseModal} />
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default ModelsUpsertForm;
