import React, { useCallback } from 'react';
import { Divider, Stack, Wrap, WrapItem, Button, Icon } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { HiOutlineTrash } from 'react-icons/hi';
import { FaRegClone } from 'react-icons/fa';
import { Draggable } from 'react-beautiful-dnd';
import CardsAction from './actions';
import InputSelect from '../../../../components/base/v2/inputs/select';
import { useRulesModels } from '../../../../providers/models';
import clone from '../../../../utils/clone';
import { getStyle } from '../../../flow/form/utils';
import handleType from '../../../../utils/handleType';
import InputFactory from '../../../../components/base/v2/inputs/factory';
import Stat from '../../../../components/stat';

import * as I from '../../interfaces';

const Feature = ({
  feature,
  featureIndex,
  featuresLength,
  appendFeatures,
  removeFeatures,
  moveFeatures,
  features,
}: I.CardProps): React.ReactElement => {
  const {
    features: featuresProvider,
    featuresNames,
    isDisabled,
  } = useRulesModels();

  const methods = useFormContext();
  const {
    getValues,
    register,
    formState,
    formState: { errors },
    control,
  } = methods;

  const handleCloneFeature = useCallback((): void => {
    appendFeatures({
      ...clone(getValues(`features.${featureIndex}`)),
    });
  }, []);

  const handleRemoveFeature = useCallback((): void => {
    removeFeatures(featureIndex);
  }, [featureIndex]);

  const ruleName = useWatch({
    control,
    name: `features.${featureIndex}.name`,
  });

  const options = featuresNames.map((item) => ({ value: item, option: item }));

  return (
    <Draggable
      draggableId={`model-draggable-${feature.id}`}
      index={featureIndex}
      isDragDisabled={isDisabled}
    >
      {(dragProvider, snapshot): React.ReactElement => (
        <div
          ref={dragProvider.innerRef}
          style={getStyle(snapshot, dragProvider.draggableProps.style)}
          {...dragProvider.draggableProps}
        >
          <Stack
            direction="row"
            spacing="regular"
            p="medium"
            background="white"
            borderRadius="0.75rem"
            mb="8px"
          >
            <CardsAction
              dragProvider={dragProvider}
              moveFeature={moveFeatures}
              featureIndex={featureIndex}
              features={features}
            />

            <div>
              <Divider orientation="vertical" borderColor="neutral.400" />
            </div>

            {isDisabled ? (
              <Stat title="Feature" text={feature.name} identifier="name" />
            ) : (
              <Wrap spacing="regular">
                <WrapItem>
                  <InputSelect
                    label="Feature Name"
                    options={options}
                    {...register(`features.${featureIndex}.name`)}
                    formcontrol={{
                      isDisabled,
                      isRequired: true,
                      error: errors.features?.[featureIndex]?.name,
                    }}
                    mr="16px"
                  />
                </WrapItem>
                {featuresProvider
                  ?.find((item) => item.name === ruleName)
                  ?.parameters?.map((param) => {
                    const key = `features.${featureIndex}.parameters.${param.name}`;
                    return (
                      <WrapItem key={key}>
                        {InputFactory({
                          name: param.name,
                          path: `features.${featureIndex}.parameters.${param.name}`,
                          type: handleType(param),
                          defaultValue: param.default,
                          isRequired: param.mandatory,
                          multiple: param.type === 'array of strings',
                          description: param.description,
                          values: param.one_of,
                          methods,
                          isDisabled,
                        })}
                      </WrapItem>
                    );
                  })}
              </Wrap>
            )}

            <Stack direction="row" marginLeft="auto !important">
              <Button
                onClick={handleCloneFeature}
                isLoading={formState.isSubmitting}
                isDisabled={isDisabled}
              >
                <Icon as={FaRegClone} />
              </Button>
              <Button
                onClick={handleRemoveFeature}
                isLoading={formState.isSubmitting}
                isDisabled={featuresLength <= 1 || isDisabled}
              >
                <Icon as={HiOutlineTrash} />
              </Button>
            </Stack>
          </Stack>
        </div>
      )}
    </Draggable>
  );
};

export default Feature;
