import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import GenericForm from '../../../components/base/v2/genericForm';
import useBlocks from '../../../hooks/api/blocks';
import FlowUpsertForm from '../../../sections/flow/form';
import CloneBlockModal from '../../../components/base/v2/block/cloneBlockModal';
import { constants } from '../../../constants';
import { isEditingFlow, ResetAtom } from '../../../sections/flow/form/store';
import { handleFormDataToModel } from '../../rulesFlow/utils';
import { emptyFlowObject } from '../../../providers/rulesFlow/utils';
import { useRulesFlow } from '../../../providers/rulesFlow';
import { formatConstants } from '../../../utils/formatConstants';

import * as I from '../../../interfaces/blocks';
import * as I2 from '../../../interfaces/flow';

const BlocksInfo = () => {
  const [initialValues, setInitialValues] =
    useState<I.BlocksModel>(emptyFlowObject);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useAtom(isEditingFlow);
  const { resetIsEditingFlow, resetIsEditingNodeAtom } = ResetAtom();

  const { rulesData } = useRulesFlow();
  const { updateBlock, getOneBlock } = useBlocks();
  const { id } = useParams<{ id: string }>();

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };

  const loadInfo = async () => {
    try {
      setIsLoading(true);
      const { data } = await getOneBlock({ id });
      setInitialValues(data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (data: I2.FluxModelForm) => {
    try {
      const res = await updateBlock({
        data: handleFormDataToModel(rulesData.rules, data),
        id,
        version: initialValues.version,
      });
      if ([200, 201, 204].includes(res.request.status)) {
        handleEditing();
        loadInfo();
        const message = formatConstants(constants.updatedWithSuccess, 'Bloco');
        toast.success(message);
        return;
      }
      const { reason } = JSON.parse(res.request.response);
      toast.error(`${constants.errorToSubmitWithReason}: ${reason}`);
    } catch {
      toast.error(constants.errorToSubmitContent);
    }
  };

  useEffect(() => {
    loadInfo();
    resetIsEditingFlow();
    resetIsEditingNodeAtom();
  }, []);

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Bloco', href: '/blocks' },
        { label: initialValues?.description },
      ]}
      title={initialValues?.description}
      isDisabled={!isEditing}
      handleAction={handleEditing}
      actionText="Editar Bloco"
      backButtonUrl="/blocks"
      copyText={initialValues?.id}
      menuItem={<CloneBlockModal block={initialValues} />}
      showBackButton
    >
      <FlowUpsertForm
        flow={initialValues}
        isLoaded={!isLoading}
        handleSubmit={handleSubmit}
        isBlock
      />
    </GenericForm>
  );
};

export default BlocksInfo;
