import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Image } from '@chakra-ui/react';
import squareInfoIcon from '../../../../../assets/icons/squareInfoIcon.svg';
import bagIcon from '../../../../../assets/icons/bagIcon.svg';
import SidebarDropdown from './dropdown';
import CheckRoles from '../../../../../utils/checkRoles';
import { OrderOptions, sidebarOptions, ruleOptions } from '../utils';

import * as S from '../styles';
import * as I from '../interface';

const Sidebar = ({ onlyIcons }: I.SidebarProps) => {
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [roles] = useState<string[]>(CheckRoles({ isMenu: true }) as string[]);

  useEffect(() => {
    if (pathname.includes('orders/info')) {
      return setShowMenu(false);
    }
    return setShowMenu(true);
  }, [pathname]);

  if (CheckRoles({ roles: [''] })) {
    return <></>;
  }

  return (
    <>
      {showMenu && (
        <S.Container $hasOnlyIcons={onlyIcons} data-testid="sidebar-container">
          {roles?.includes('view_analysis') && (
            <SidebarDropdown
              title="Pedidos"
              icon={bagIcon}
              options={OrderOptions}
              onlyIcons={onlyIcons}
              roles={roles}
            />
          )}

          {sidebarOptions.map((item) => {
            return (
              <S.Row key={item.name}>
                {roles?.includes(item.role) && (
                  <S.RowItem to={item.link}>
                    <Image
                      alt={item.name}
                      src={item.icon}
                      mr={4}
                      width="21px"
                      height="21px"
                    />
                    <Box as="span">{item.name}</Box>
                  </S.RowItem>
                )}
              </S.Row>
            );
          })}
          {roles?.includes('view_flows') && (
            <SidebarDropdown
              title="Fluxos"
              icon={squareInfoIcon}
              options={ruleOptions}
              onlyIcons={onlyIcons}
              roles={roles}
            />
          )}
        </S.Container>
      )}
    </>
  );
};

export default Sidebar;
