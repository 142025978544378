/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiLink2 } from 'react-icons/fi';
import { QueryResult } from '@material-table/core';
import { toast } from 'react-toastify';
import useClients from '../../../../hooks/api/clients';
import useQuery from '../../../../hooks/queryParams';
import useActionLists from '../../../../hooks/api/actionLists';
import GenericForm from '../../../../components/base/v2/genericForm';
import DefaultTable, {
  fetchDataTable,
} from '../../../../components/base/v2/table';
import { LoadingContainer } from '../../../clients/info/styles';
import LoadingSpinner from '../../../../components/loadingSpinner';

import {
  nameAndIDColumn,
  documentColumn,
  ExternalIdColumn,
  IdColumn,
} from '../../../../components/base/v2/table/columns';

import { LoadDataQuery } from './interfaces';
import { ActionsListModel } from '../../../../interfaces/actionsList';
import { ClientModel } from '../../../../interfaces/client';

export default function ActionsListUpdateAction(): React.ReactElement {
  const [isLoading, setIsLoading] = useState(true);
  const [action, setAction] = useState<ActionsListModel>();

  const { getClients, deleteClientsBonds, postClientsBonds } = useClients();
  const { getOneActionList } = useActionLists();
  const tableRef = useRef<any>();

  const queryParam = useQuery();
  const actionID = queryParam.get('id') ?? undefined;

  const onButtonClick = () => {
    if (tableRef.current) {
      tableRef.current.dataManager?.changeRowEditing();
      tableRef.current.setState({
        ...tableRef.current.dataManager?.getRenderState(),
        showAddRow: true,
      });
    }
  };

  const reloadTable = useCallback(() => {
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange();
    }
  }, []);

  const loadData = (
    query: LoadDataQuery
  ): Promise<QueryResult<ClientModel>> => {
    const showErrorToast = () => {
      toast.error(
        'Ocorreu um erro inesperado ao buscar os clientes vinculados. Tente novamente mais tarde.'
      );
    };

    query.actionID = actionID;

    return fetchDataTable(query, getClients, undefined, showErrorToast);
  };

  const loadInfo = useCallback(async (): Promise<ClientModel> => {
    setIsLoading(true);

    const response = await getOneActionList(actionID);
    setAction(response.data);

    return response.data;
  }, []);

  const clientTableEditWithDelete = {
    onRowAdd: (data: { name: ClientModel }): Promise<void> =>
      new Promise((resolve) => {
        setTimeout(() => {
          const client = data.name;

          if (client?.id === undefined) {
            toast.warn(
              'É preciso selecionar um cliente para realizar o vínculo.'
            );
            resolve(reloadTable());
            resolve();
            return;
          }

          postClientsBonds({
            url: `clients/${client.id}/action-lists/${action?.id}`,
            version: client.version,
          })
            .then((res) => {
              if ([200, 201, 204].includes(res.request.status)) {
                toast.success('Vínculo realizado com sucesso!');
                setTimeout(() => {
                  reloadTable();
                  resolve();
                }, 1000);
                return;
              }
              const { reason } = JSON.parse(res.request.response);
              toast.error(`Não foi possível realizar o vínculo. ${reason}`);
            })
            .catch(() => {
              toast.error(`Não foi possível realizar o vínculo.`);
              setTimeout(() => {
                reloadTable();
                resolve();
              }, 1000);
            });
        }, 1000);
      }),
    onRowDelete: (data: ClientModel): Promise<void> =>
      new Promise((resolve) => {
        setTimeout(() => {
          deleteClientsBonds({
            url: `clients/${data.id}/action-lists/${action?.id}`,
            version: data.version,
          })
            .then(() => {
              toast.success('Vínculo desfeito com sucesso!');
              setTimeout(() => {
                reloadTable();
                resolve();
              }, 1000);
            })
            .catch(() => {
              toast.error('Não foi possível desfazer o vínculo.');
              setTimeout(() => {
                reloadTable();
                resolve();
              }, 1000);
            });
        }, 1000);
      }),
  };

  const tableColumns = [
    nameAndIDColumn('/client/info'),
    IdColumn,
    ExternalIdColumn,
    documentColumn,
  ];

  useEffect(() => {
    loadInfo().finally(() => {
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Listas', href: '/actions-list' },
        {
          label: action?.name ?? '',
          href: `/actions-list/info?id=${action?.id}`,
        },
        { label: 'Clientes' },
      ]}
      title={action?.name ?? ''}
      handleAction={onButtonClick}
      actionText="Vincular novo cliente"
      buttonIcon={FiLink2}
      isDisabled
      showBackButton
    >
      <DefaultTable
        columns={tableColumns}
        data={(query) => loadData(query)}
        searchLabel="Busque por nome ou ID"
        accessURL="/client/info"
        editable={clientTableEditWithDelete}
        tableRef={tableRef}
      />
    </GenericForm>
  );
}
