import React, { useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { AbsoluteCenter, Icon } from '@chakra-ui/react';
import { BiEditAlt } from 'react-icons/bi';

import * as S from '../../styles/main';

import useQuery from '../../hooks/queryParams';
import GenericFormPage from '../../templates/genericForm';
import { defaultQueueManagement } from '../../components/complex/form/queuesManagement/utils';
import {
  isQueuesManagementEditingAtom,
  initialQueuesManagementDataAtom,
  queuesFiltersAtom,
} from '../../components/complex/form/queuesManagement/stores';
import LoadingSpinner from '../../components/loadingSpinner';
import useQueues from '../../hooks/api/queue';
import QueuesManagementForm from '../../components/complex/form/queuesManagement';
import { QueueManagementFiltersModel } from '../../interfaces/queueManagement';

const QueuesManagementFormPage: React.FC = () => {
  const query = useQuery();
  const { getOneQueue, getAllQueueFilters } = useQueues();
  const id = query.get('id');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isQueueEditing, setIsQueueEditing] = useAtom(
    isQueuesManagementEditingAtom
  );
  const [initialQueueData, setInitialQueueDataAtom] = useAtom(
    initialQueuesManagementDataAtom
  );

  const [, setQueueFilters] = useAtom(queuesFiltersAtom);

  const actions = [
    {
      text: 'Editar fila',
      icon: <Icon as={BiEditAlt} />,
      onClick: () => setIsQueueEditing(!isQueueEditing),
    },
  ];

  const breadcrumbLabel = initialQueueData.name || 'Nova fila';
  const title = initialQueueData.name
    ? `Fila: ${initialQueueData.name}`
    : `Filas`;

  const showActions = !isQueueEditing && id;

  const loadInfo = useCallback(async (): Promise<void> => {
    setInitialQueueDataAtom(defaultQueueManagement);
    setIsQueueEditing(true);

    const responseFilters = await getAllQueueFilters();
    setQueueFilters({
      filters: responseFilters.data.items,
      filtersName: responseFilters.data.items.map(
        (item: QueueManagementFiltersModel) => ({
          label: item.name,
          value: item.name,
        })
      ),
    });

    if (id) {
      const response = await getOneQueue({ id });

      response.data.filters.forEach((filter: QueueManagementFiltersModel) => {
        if (filter.name === 'analysis/score') {
          filter.parameters.score *= 100;
        }
      });

      setInitialQueueDataAtom(response.data);
      setIsQueueEditing(false);
    }

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    loadInfo();
  }, [id]);

  if (!isLoaded) {
    return (
      <S.Main>
        <AbsoluteCenter>
          <LoadingSpinner />
        </AbsoluteCenter>
      </S.Main>
    );
  }

  return (
    <GenericFormPage
      breadcrumb={{
        items: [
          { label: 'Início', href: '/' },
          { label: 'Filas', href: '/gestao-de-filas' },
          { label: breadcrumbLabel },
        ],
      }}
      title={{
        text: title,
        showBackButton: true,
      }}
      actions={showActions ? actions : undefined}
    >
      <QueuesManagementForm loadInfo={loadInfo} />
    </GenericFormPage>
  );
};

export default QueuesManagementFormPage;
