import { Column } from '@material-table/core';
import * as Yup from 'yup';
import { ActionsListItemModel } from '../../../interfaces/actionsList';
import { theme } from '../../../styles/theme';
import { ActionsType, TypeOptions } from './interfaces';

export const validateSchema = Yup.object().shape({
  name: Yup.string().required('É preciso informar o nome.'),
  description: Yup.string(),
});

const handleValidation = (type: string, target: string) => {
  const validations = {
    'seller-id': target?.length > 0,
    'seller-document': target?.length > 0,
    'seller-name': target?.length > 0,
    'client-id': target?.length > 0,
    bin: target?.length > 0,
    document: target?.length > 5,
    email: target?.length > 0,
    phone: target?.length >= 6,
    'postal-code': target?.length > 1 && /^\d+$/.test(target),
  };

  if (type && type in validations) {
    return validations[type as TypeOptions];
  }

  if (type) {
    return true;
  }

  return false;
};

const lookUpType = {
  document: 'Documento',
  email: 'E-mail',
  phone: 'Telefone/celular',
  'postal-code': 'Código postal',
  'seller-id': 'Seller-id',
  'seller-document': 'Seller-document',
  'seller-name': 'Seller-name',
  'client-id': 'Client-id',
  bin: 'Bin',
  'email-domain': 'Email-domain',
};

const actionBackground = {
  approve: theme.colors.v2.green[500],
  review: theme.colors.v2.orange[300],
  reject: theme.colors.v2.red[300],
};

const actionText = {
  approve: 'Aprovar',
  review: 'Revisar',
  reject: 'Reprovar',
};

export const clientColumns: Column<ActionsListItemModel>[] = [
  {
    title: 'Tipo',
    field: 'type',
    lookup: lookUpType,
    validate: (rowData): boolean => !!rowData.type,
  },
  {
    title: 'Valor',
    field: 'target',
    validate: (rowData): boolean => {
      const { type, target } = rowData;

      return handleValidation(type, target);
    },
  },
  {
    title: 'Ação ',
    field: 'action',
    lookup: { approve: 'Aprovar', reject: 'Reprovar', review: 'Revisar' },
    cellStyle: { padding: '5px 20px' },
    render: (rowData): React.ReactNode => {
      return (
        <p
          style={{
            background: actionBackground[rowData.action as ActionsType],
            padding: '7px 15px 7px',
            borderRadius: 5,
            width: '100%',
            textAlign: 'center',
          }}
        >
          {actionText[rowData.action as ActionsType]}
        </p>
      );
    },
  },
  {
    title: 'Data de expiração',
    field: 'expires_at',
    type: 'datetime',
    width: 195,
    emptyValue: 'Sem data',
    dateSetting: { format: 'DD/MM/YYYY HH:mm:ss' },
  },
  {
    title: 'Data da criação',
    field: 'created_at',
    editable: 'never',
    width: 195,
    type: 'datetime',
    dateSetting: { format: 'DD/MM/YYYY HH:mm:ss' },
  },
  {
    title: 'Criado por',
    field: 'modified_by',
    editable: 'never',
  },
];
