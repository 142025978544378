import { useMsal } from '@azure/msal-react';

export const menuRoles = [
  'view_clients',
  'view_flows',
  'view_actions',
  'view_models',
  'view_partners',
  'view_analysis',
  'view_queues',
  'view_groups',
  'resolve_suspension',
  'resolve_analysis_withhold',
  'view_tags',
  'create_tags',
];

interface CheckRolesType {
  roles?: string[];
  isMenu?: boolean;
}

const CheckRoles = ({ roles, isMenu }: CheckRolesType) => {
  const { accounts } = useMsal();
  const userRole = accounts[0]?.idTokenClaims?.roles;

  if (userRole) {
    if (isMenu) {
      return userRole.filter((item) => menuRoles.includes(item));
    }

    if (roles) {
      return userRole.filter((item) => roles.includes(item)).length > 0;
    }
  }

  return [''];
};

export default CheckRoles;
