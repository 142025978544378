/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldError } from 'react-hook-form';
import {
  OptionProps,
  components,
  StylesConfig,
  PlaceholderProps,
} from 'react-select';
import { theme } from '../../../../styles/theme';
import * as I from './interfaces';

export const getError = ({
  errors,
  path,
}: I.GetErrorProps): FieldError | undefined => {
  const pathParts = path.split('.');
  return pathParts.reduce((currentError, part) => {
    if (typeof currentError === 'object' && currentError !== null) {
      return currentError[part];
    }
    return undefined;
  }, errors as any);
};

export const booleanWithNullOption = [
  { option: ' ', value: 'null' },
  { option: 'Sim', value: 'true' },
  { option: 'Não', value: 'false' },
];

export const booleanOption = [
  { option: 'Sim', value: 'true' },
  { option: 'Não', value: 'false' },
];

export const customStyles: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    minHeight: 44,
    minWidth: 180,
    borderRadius: '0.5rem',
    borderColor: state.isFocused
      ? theme.colors.blue[900]
      : theme.colors.neutral[500],
    boxShadow: state.isFocused ? `0 0 0 1px ${theme.colors.blue[900]}` : '',
    '&:hover': {
      borderColor: theme.colors.neutral[300],
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
};

export const Option = (props: OptionProps) => {
  const { isSelected, label: labelOption } = props;
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        style={{ marginRight: 5 }}
        checked={isSelected}
        readOnly
      />
      <span>{labelOption}</span>
    </components.Option>
  );
};

export const Placeholder = (props: PlaceholderProps) => {
  return <components.Placeholder {...props} />;
};
