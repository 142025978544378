import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Icon, Stack } from '@chakra-ui/react';
import { BiUnlink } from 'react-icons/bi';
import { FiExternalLink } from 'react-icons/fi';

import * as I from '../interfaces';

import AutocompleteFlow from '../../../../components/base/autocompleteFlow';
import { H3 } from '../../../../components/base/headings';
import { handleExternalLink } from '../../../../utils/externalLink';

const RulesFlow = ({
  handleUnlinkFlow,
  isDisabled,
  value,
  setValue,
  label,
  spacing = 'md',
}: I.BondsProps): React.ReactElement => {
  const { register, control, setValue: setInputValue } = useFormContext();

  const flowID = useWatch({
    control,
    name: 'flow_id',
  });

  const handleSetValues = (val: string) => {
    setValue(val);
    setInputValue('flow_id', val);
  };

  return (
    <Stack spacing={spacing}>
      <H3>{label ?? 'Fluxo Vinculado'}</H3>
      <Stack direction="row" spacing={spacing} alignItems="center">
        <div
          className="default--input"
          style={{ flex: 1 }}
          data-testid="flows-input"
        >
          <AutocompleteFlow
            {...register('flow_id')}
            action={handleSetValues}
            disabled={isDisabled}
            defaultValue={value}
            name="flow"
          />
        </div>

        {flowID && isDisabled && (
          <Button
            data-testid="external-link"
            type="button"
            onClick={() => handleExternalLink(`/rules-flow/info/${flowID}`)}
            colorScheme="gray"
            title="Acessar esse fluxo"
          >
            <Icon as={FiExternalLink} />
          </Button>
        )}

        {handleUnlinkFlow && (
          <Button
            type="button"
            isDisabled={isDisabled || !value}
            colorScheme="gray"
            onClick={(): void => {
              handleUnlinkFlow(flowID);
            }}
          >
            <Icon as={BiUnlink} />
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default RulesFlow;
