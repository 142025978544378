import React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import BackPage from '../backPage';
import copyText from '../../../utils/copyText';
import {
  HeadingProps,
  TitleWithBackPageProps,
} from '../../../interfaces/components/base/heading';

export function TitleWithBackPage({
  children,
  to,
  text,
  subId,
  ...rest
}: TitleWithBackPageProps): React.ReactElement {
  return (
    <Heading
      as="h1"
      size="xl"
      margin="30px 0 27px"
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      {...rest}
    >
      <BackPage text={text} to={to} /> {children}
      {!!subId && (
        <Text
          as="span"
          marginLeft="7px"
          marginTop="7px"
          color="blue.900"
          fontSize="lg"
          cursor="pointer"
          onClick={(): void => copyText(subId)}
        >
          (ID: {subId.substring(0, 6)}...)
        </Text>
      )}
    </Heading>
  );
}

TitleWithBackPage.defaultProps = {
  to: '/',
  text: 'Back to Home',
  subId: '123456',
};

export function H1({ children, ...rest }: HeadingProps): React.ReactElement {
  return (
    <Heading as="h1" {...rest}>
      {children}
    </Heading>
  );
}

H1.defaultProps = {
  size: 'xl',
};

export function H2({ children, ...rest }: HeadingProps): React.ReactElement {
  return (
    <Heading as="h2" {...rest}>
      {children}
    </Heading>
  );
}

H2.defaultProps = {
  size: 'sm',
};

export function H3({ children, ...rest }: HeadingProps): React.ReactElement {
  return (
    <Heading as="h3" {...rest}>
      {children}
    </Heading>
  );
}

H3.defaultProps = {
  size: 'sm',
};

export function H4({ children, ...rest }: HeadingProps): React.ReactElement {
  return (
    <Heading as="h4" {...rest}>
      {children}
    </Heading>
  );
}

H4.defaultProps = {
  size: 'sm',
};
