import React, { useEffect } from 'react';
import { Icon, MenuItem, useDisclosure } from '@chakra-ui/react';
import { FaRegClone } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import GenericForm from '../../../components/base/v2/genericForm';
import useModels from '../../../hooks/api/models';
import { useRulesModels } from '../../../providers/models';
import ModelsUpsertForm from '../../../sections/models/form';
import { formatConstants } from '../../../utils/formatConstants';
import { constants } from '../../../constants';
import { ModelsModel } from '../../../interfaces/models';
import LoadingSpinner from '../../../components/loadingSpinner';

import { LoadingContainer } from './styles';

const ModelsUpdate: React.FC = () => {
  const { push } = useHistory();
  const { updateModels } = useModels();
  const {
    dataContext,
    isLoaded,
    handleDisabled,
    isDisabled,
    getNewVersion,
    setIsDisabled,
  } = useRulesModels();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleSubmit = async (data: ModelsModel) => {
    try {
      await updateModels({
        id: dataContext.id,
        data,
        version: dataContext.version,
      });
      handleDisabled();
      getNewVersion();

      const message = formatConstants(constants.publishedWithSuccess, 'Modelo');
      toast.success(message);
    } catch (err: unknown) {
      if (err instanceof Error) {
        toast.error(`${constants.errorToSubmitWithReason}: ${err.message}`);
      }
    }
  };

  const handleDirectToList = () => {
    push(`/models/info/clients/${dataContext.id}`);
  };

  const MenuItemButton = () => (
    <MenuItem icon={<Icon as={FaRegClone} />} onClick={onOpen}>
      Clonar Modelo
    </MenuItem>
  );

  useEffect(() => {
    setIsDisabled(true);
  }, []);

  if (!isLoaded) {
    return (
      <LoadingContainer>
        <LoadingSpinner />;
      </LoadingContainer>
    );
  }

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Modelos', href: '/models' },
        { label: dataContext?.description },
      ]}
      showBackButton
      title={dataContext?.description}
      handleAction={handleDisabled}
      backButtonUrl="/models"
      actionText="Editar Modelo"
      isDisabled={isDisabled}
      secondaryActions={[
        {
          text: 'Clientes',
          action: handleDirectToList,
        },
      ]}
      menuItem={<MenuItemButton />}
    >
      <ModelsUpsertForm
        model={dataContext}
        isOpenModal={isOpen}
        onCloseModal={onClose}
        handleSubmit={handleSubmit}
      />
    </GenericForm>
  );
};

export default ModelsUpdate;
