import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAtom } from 'jotai';
import GenericForm from '../../../components/base/v2/genericForm';
import FlowUpsertForm from '../../../sections/flow/form';
import useRulesFlows from '../../../hooks/api/rulesFlows';
import { constants } from '../../../constants';
import { useRulesFlow } from '../../../providers/rulesFlow';
import { emptyFlowObject } from '../../../providers/rulesFlow/utils';
import { formatConstants } from '../../../utils/formatConstants';
import { handleFormDataToModel } from '../utils';
import { isEditingFlow } from '../../../sections/flow/form/store';

import * as I from '../../../interfaces/flow';

const RulesFlowCreate = () => {
  const [, setIsEditing] = useAtom(isEditingFlow);
  const { rulesData, isLoaded } = useRulesFlow();
  const { createFlow } = useRulesFlows();
  const history = useHistory();

  const handleSubmit = async (data: I.FluxModelForm) => {
    try {
      const res = await createFlow({
        data: handleFormDataToModel(rulesData.rules, data),
      });
      if ([200, 201, 204].includes(res.request.status)) {
        const message = formatConstants(
          constants.publishedWithSuccess,
          'Fluxo'
        );
        toast.success(message);
        history.push(`/rules-flow/info/${res.data.id}`);
        return;
      }
      const { reason } = JSON.parse(res.request.response);
      toast.error(`${constants.errorToSubmitWithReason}: ${reason}`);
    } catch {
      toast.error(constants.errorToSubmitContent);
    }
  };

  useEffect(() => {
    setIsEditing(true);
  });

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Fluxo de Regras', href: '/rules-flow' },
        { label: 'Cadastro' },
      ]}
      title="Cadastro de Fluxo de Regras"
      backButtonUrl="/rules-flow"
      showBackButton
    >
      <FlowUpsertForm
        flow={emptyFlowObject}
        isLoaded={isLoaded}
        handleSubmit={handleSubmit}
      />
    </GenericForm>
  );
};

export default RulesFlowCreate;
