import React from 'react';
import { Grid, GridItem, Stack } from '@chakra-ui/react';
import Stat from '../../../../components/stat';
import Badge from '../../../../components/badge';
import { checkBoolean } from '../../../../utils/checkValues';
import { formatDate } from '../../../../utils/formatDate';

import * as I from '../interfaces';

const InformationBase: React.FC<I.InformationBaseProps> = ({
  dataTestId,
  identifierPrefix,
  name,
  flow,
  isRetention,
}) => {
  return (
    <Stack
      data-testid={dataTestId}
      spacing="medium"
      background="white"
      direction="row"
      p="medium"
      borderRadius="extra-large"
      mb="16px"
    >
      <Grid templateColumns="repeat(6, 1fr)" gap="medium" width="100%">
        <GridItem colSpan={2}>
          <Stat
            title={name}
            text={flow.description}
            identifier={`${identifierPrefix}_description`}
          />
        </GridItem>

        <GridItem colSpan={1}>
          {!isRetention && 'allow_review' in flow && (
            <Stat
              title="Permitir revisão"
              text={checkBoolean(flow.allow_review)}
              identifier={`${identifierPrefix}_allow-review`}
            />
          )}
        </GridItem>
        <GridItem colSpan={1}>
          <Stat
            title="Última modificação"
            text={formatDate(flow.modified_at)}
            identifier={`${identifierPrefix}_modified-at`}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Stat
            title="Modificado por"
            text={flow.modified_by}
            identifier={`${identifierPrefix}_modified-by`}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Stat title="Status" identifier={`${identifierPrefix}_status`}>
            <Badge
              label={flow.status === 'enabled' ? 'Ativo' : 'Inativo'}
              schema={flow.status === 'enabled' ? 'green' : 'red'}
              transparent
            />
          </Stat>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default InformationBase;
