/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleClientsModelToForm } from '../../../sections/client/form/utils';

import useQuery from '../../../hooks/queryParams';
import useClients from '../../../hooks/api/clients';
import GenericForm from '../../../components/base/v2/genericForm';
import LoadingSpinner from '../../../components/loadingSpinner';
import ClientUpsertForm from '../../../sections/client/form';

import * as I from '../../../interfaces/client';
import * as S from './styles';

const ClientsInfo: React.FC = () => {
  const { push } = useHistory();
  const { getOneClient, updateClient } = useClients();

  const [client, setClient] = useState<I.ClientModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const query = useQuery();
  const id = query.get('id') ?? undefined;

  const loadInfo = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const { data } = await getOneClient({ id });
    setClient(data);

    setIsLoading(false);
  }, []);

  const handleEditing = () => {
    setIsDisabled(!isDisabled);
  };

  const handleDirectToList = () => {
    push(`/client/info/list?id=${client?.id}`);
  };

  useEffect(() => {
    loadInfo();
  }, []);

  const handleSubmit = async ({ data }: I.ClientHandleSubmitType) => {
    return updateClient({
      id: client?.id!,
      data,
      version: client?.version!,
    })
      .then((res) => {
        if ([200, 201, 204].includes(res.request.status)) {
          toast.success('Cliente alterado com sucesso!');
          setClient(res.data);
          setIsDisabled(true);
          return res;
        }
        const { reason } = JSON.parse(res.request.response);
        toast.warn(`Não foi possível alterar o cliente ${reason}`);
        return res;
      })
      .catch(() => {
        toast.warn(`Não foi possível alterar o cliente`);
      });
  };

  if (isLoading) {
    return (
      <S.LoadingContainer>
        <LoadingSpinner />
      </S.LoadingContainer>
    );
  }

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Cliente', href: '/client' },
        { label: client?.name ? client.name : 'Cadastro de Cliente' },
      ]}
      title={client?.name ? client.name : 'Cadastro de Cliente'}
      showBackButton
      backButtonUrl="/client"
      handleAction={handleEditing}
      actionText="Editar Cliente"
      isDisabled={isDisabled}
      secondaryActions={[
        {
          text: 'Listas',
          action: handleDirectToList,
        },
      ]}
      copyText={client?.id}
    >
      <>
        {client && (
          <ClientUpsertForm
            handleSubmit={handleSubmit}
            clientData={handleClientsModelToForm(client)}
            isDisabled={isDisabled}
            loadInfo={loadInfo}
          />
        )}
      </>
    </GenericForm>
  );
};

export default ClientsInfo;
