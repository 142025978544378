import styled from 'styled-components';
import {
  Accordion,
  AccordionPanel,
  AccordionButton,
  Button,
  Text,
  Stack,
} from '@chakra-ui/react';
import Select from '../base/input/select/chakraSelect';
import InputSearchSelect from '../base/v2/inputs/searchselect';

export const Container = styled(Accordion)`
  width: 400px;
  position: fixed;
  bottom: 0;
  right: ${({ positionX }) => (positionX === 'right' ? '48px' : '')};
  left: ${({ positionX }) => (positionX === 'left' ? '48px' : '')};
  z-index: 1001;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[100]};
  border-radius: ${({ theme }) => theme.spacing(1.5)}px
    ${({ theme }) => theme.spacing(1.5)}px 0px 0px;

  button:focus {
    outline: 0;
    box-shadow: none !important;
  }
`;

export const FormContainer = styled(AccordionPanel)`
  height: 500px;
  padding: 0px 0 70px 0 !important;
`;

export const ButtonsContainer = styled(Stack)`
  right: ${({ positionX }) => (positionX === 'right' ? '65px' : '')};
  left: ${({ positionX }) => (positionX === 'left' ? '50px' : '')};
  width: 100%;
  padding: 14px 16px 0 16px;
  background: #fff !important;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
`;

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  word-break: break-word;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1.5)}px
    ${({ theme }) => theme.spacing(2.5)}px !important;
`;

export const StyledButton = styled(AccordionButton)`
  padding: ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(2.5)}px !important;
  flex: 0;
`;

export const SelectContainer = styled.div`
  width: 255px;
  margin-right: ${({ theme }) => theme.spacing(3.75)}px;
`;

export const TitleText = styled(Text).attrs(() => ({
  fontSize: 'large',
  fontWeight: '600',
  color: 'v2.black.200',
}))``;

export const TextArea = styled.textarea`
  width: 350px;
  border: 1px solid ${({ theme }) => theme.colors.v2.dark[100]};
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(1.25)}px;
  resize: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.v2.gray[200]};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.v2.gray[200]};
  }
  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.v2.gray[200]};
  }
`;

export const AnalysisButton = styled(Button).attrs(() => ({
  backgroundColor: '#00A868',
}))`
  padding: 14px 20px;
  color: ${({ theme }) => theme.colors.v2.white[100]};
`;

export const DatePickerContainer = styled.div`
  width: 96%;
`;

export const StyledSelect = styled(Select)`
  width: ${({ theme }) => theme.spacing(25)}px !important;
  height: ${({ theme }) => theme.spacing(5)}px !important;
`;

export const ReviewSummary = styled.div`
  width: 100%;
  max-height: 300px;
  background: ${({ theme }) => theme.colors.v2.gray[100]};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(1.75)}px
    ${({ theme }) => theme.spacing(1)}px;
  margin-top: 8px;
  overflow-y: scroll;
`;

export const NodeText = styled(Text).attrs(() => ({
  fontSize: '14px',
}))`
  color: ${({ theme }) => theme.colors.v2.dark[200]};
  margin-right: 5px;
`;

export const StyledSeachInput = styled(InputSearchSelect)`
  width: 350px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const InputContainer = styled.div`
  width: 100%;
  height: 435px;
  overflow-y: scroll;
  padding-left: 18px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;
