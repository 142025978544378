/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-throw-literal */
import { AxiosError } from 'axios';

import { trexApi } from '../../../services/api';
import { getUUID } from '../../../services/storage';
import { handleApiRequest } from '../utils';
import checkValid from '../../../utils/checkValid';

import * as I from '../interfaces';
import * as I2 from './interfaces';
import { ClientModelRequestType } from '../../../interfaces/client';

const useClients = () => {
  const UUID = getUUID();
  const prefix = 'clients';

  const getClients = async ({
    page,
    pageSize,
    orderBy,
    orderDirection,
    search,
    actionID,
    flowID,
    groupID,
    modelID,
    partnerID,
    retentionFlow,
  }: I2.ClientsAPIsQueryType) => {
    const response = await handleApiRequest(
      trexApi.get(prefix, {
        params: {
          'action-list': actionID ?? null,
          model: modelID ?? null,
          flow: flowID ?? null,
          partner: partnerID ?? null,
          group: groupID ?? null,
          'retention-flow': retentionFlow ?? null,
          page: page + 1,
          'page-size': pageSize,
          'sort-by': orderBy?.field
            ? `${orderBy.field.toString()},${orderDirection}`
            : null,
          search: checkValid(search),
        },
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const getAllClients = async ({ data, query }: I.GetAllClientsType) => {
    try {
      const response = await trexApi.get(prefix, {
        params: {
          'action-list': data.action ? data.action.id : null,
          model: data.model ? data.model.id : null,
          flow: data.flow ? data.flow.id : null,
          'retention-flow': data.retentionFlow ? data.retentionFlow : null,
          partner: data.partner ? data.partner.id : null,
          group: data.groups ? data.groups.id : null,
          page: query.page + 1,
          'page-size': query.pageSize,
          'sort-by': query.orderBy?.field
            ? `${query.orderBy.field.toString()},${query.orderDirection}`
            : null,
          search: query.search ? query.search : null,
        },
        headers: {
          'X-SetID': UUID,
        },
      });
      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const createClient = async (data: ClientModelRequestType) => {
    try {
      const response = await trexApi.post(prefix, data, {
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const getOneClient = async ({ id }: Pick<I2.ClientsAPIsProps, 'id'>) => {
    try {
      const response = await trexApi.get(`${prefix}/${id}`, {
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const updateClient = async ({
    id,
    data,
    version,
  }: I.PostUpdateClientType) => {
    try {
      const response = await trexApi.patch(`${prefix}/${id}`, data, {
        headers: {
          'if-match': version,
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const postClientsFlow = async ({
    id,
    scopeID,
    version,
  }: I.GenericBondRequestType) => {
    try {
      const response = await trexApi.post(
        `${prefix}/${id}/flows/${scopeID}`,
        null,
        {
          headers: {
            'if-match': version,
            'X-SetID': UUID,
          },
        }
      );

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const postClientsRetentionFlow = async ({
    id,
    scopeID,
    version,
  }: I.GenericBondRequestType) => {
    try {
      const response = await trexApi.post(
        `${prefix}/${id}/retention-flows/${scopeID}`,
        null,
        {
          headers: {
            'if-match': version,
            'X-SetID': UUID,
          },
        }
      );

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const deleteClientsFlow = async ({
    id,
    flowID,
    version,
  }: I.DeleteClientFlow) => {
    try {
      const response = await trexApi.delete(`${prefix}/${id}/flows/${flowID}`, {
        headers: {
          'if-match': version,
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const deleteClientsRetention = async ({
    id,
    flowID,
    version,
  }: I.DeleteClientFlow) => {
    try {
      const response = await trexApi.delete(
        `${prefix}/${id}/retention-flows/${flowID}`,
        {
          headers: {
            'if-match': version,
            'X-SetID': UUID,
          },
        }
      );

      return response;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const { response } = err;
        throw new Error(response?.data.reason);
      }

      throw err;
    }
  };

  const postClientsGroup = async ({
    id,
    scopeID,
    version,
  }: I.GenericBondRequestType) => {
    try {
      const response = await trexApi.post(
        `${prefix}/${id}/groups/${scopeID}`,
        null,
        {
          headers: {
            'if-match': version,
            'X-SetID': UUID,
          },
        }
      );

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const postClientsModel = async ({
    id,
    scopeID,
    version,
  }: I.GenericBondRequestType) => {
    try {
      const response = await trexApi.post(
        `${prefix}/${id}/models/${scopeID}`,
        null,
        {
          headers: {
            'if-match': version,
            'X-SetID': UUID,
          },
        }
      );

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const deleteClientsModel = async ({
    id,
    scopeID,
    version,
  }: I.GenericBondRequestType) => {
    try {
      const response = await trexApi.delete(
        `${prefix}/${id}/models/${scopeID}`,
        {
          headers: {
            'if-match': version,
          },
        }
      );

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const postClientsActionList = async ({
    id,
    actions,
    version,
  }: I.PostClientsActionListType) => {
    try {
      const response = await trexApi.post(
        `${prefix}/${id}/action-lists/${actions}`,
        null,
        {
          headers: {
            'If-Match': version,
            'X-SetID': UUID,
          },
        }
      );

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const postClientsBonds = async ({
    url,
    version,
  }: I.PostDeleteClientsBondsType) => {
    try {
      const response = await trexApi.post(url, null, {
        headers: {
          'If-Match': version,
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const { response } = err;
        throw new Error(response?.data.reason);
      }

      throw err;
    }
  };

  const deleteClientsBonds = async ({
    url,
    version,
  }: I.PostDeleteClientsBondsType) => {
    try {
      const response = await trexApi.delete(url, {
        headers: {
          'If-Match': version,
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  return {
    getClients,
    createClient,
    getOneClient,
    updateClient,
    postClientsFlow,
    postClientsRetentionFlow,
    postClientsGroup,
    postClientsModel,
    deleteClientsModel,
    postClientsActionList,
    getAllClients,
    postClientsBonds,
    deleteClientsBonds,
    deleteClientsFlow,
    deleteClientsRetention,
  };
};

export default useClients;
