import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Divider,
  EditableInput,
  Icon,
  Stack,
  Wrap,
  WrapItem,
  Checkbox,
} from '@chakra-ui/react';
import { Draggable } from 'react-beautiful-dnd';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { HiOutlineTrash } from 'react-icons/hi';
import { CgAddR } from 'react-icons/cg';
import { BiEditAlt } from 'react-icons/bi';
import { StringOrNumber } from '@chakra-ui/utils';
import { useAtom } from 'jotai';
import InputText from '../../inputs/text';
import InputSelect from '../../inputs/select';
import Button from '../../button';
import Information from './info';
import TermsBase from './nodeTerms';
import {
  getStyle,
  policyOptions,
  typeOptions,
} from '../../../../../sections/flow/form/utils';
import { handleActionOptions } from './utils';
import {
  isEditingNodeAtom,
  isEditingFlow,
} from '../../../../../sections/flow/form/store';

import * as S from '../../../../../sections/flow/form/styles';
import * as I from './interfaces';

const Node = ({
  flowId,
  node,
  nodes,
  nodeIndex,
  moveNode,
  removeNode,
  setCheckbox,
  isBlock,
  isRetention,
}: I.NodeProps): React.ReactElement => {
  const [isFlowEditing] = useAtom(isEditingFlow);
  const [isNodeEditing, setIsNodeEditing] = useAtom(isEditingNodeAtom);
  const [isEditing, setIsEditing] = useState(false);

  const {
    register,
    formState: { errors },
    control,
    reset,
    getValues,
  } = useFormContext();

  const {
    fields: terms,
    append: appendTerms,
    remove: removeTerms,
  } = useFieldArray({
    control,
    name: `nodes.${nodeIndex}.terms`,
  });

  const actionSelected = useWatch({
    control,
    name: `nodes.${nodeIndex}.action`,
  });

  const hasOnly = nodes.length <= 1;

  const handleChange = (e: string) => {
    moveNode(nodeIndex, Number(e));
  };

  const handleRemoveNode = (): void => {
    removeNode(nodeIndex);
  };

  const handleAppendTerms = (): void => {
    appendTerms({
      complement: false,
      error_policy: '',
      rule: {
        name: '',
      },
    });
  };

  const handleEnableEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleCheckboxValue = ({
    target: { checked, value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setCheckbox((prev: StringOrNumber[]) =>
      checked
        ? [...prev, value]
        : prev.filter((item: StringOrNumber) => item !== value)
    );

  const actionOptions = handleActionOptions(isBlock, isRetention);

  useEffect(() => {
    if (!flowId || isNodeEditing) {
      setIsEditing(true);
      setIsNodeEditing(false);
    }
  }, []);

  useEffect(() => {
    const formData = getValues();
    formData.nodes = nodes;
    reset(formData, { keepDirty: true });
  }, [nodeIndex]);

  return (
    <Draggable
      draggableId={`draggable-${node.id}`}
      index={nodeIndex}
      isDragDisabled={hasOnly || !isFlowEditing}
    >
      {(dragProvider, snapshot): React.ReactElement => (
        <div
          key={`node-dnd-card-${node.id}`}
          ref={dragProvider.innerRef}
          style={getStyle(snapshot, dragProvider.draggableProps.style)}
          {...dragProvider.draggableProps}
        >
          <AccordionItem background="white" borderRadius="extra-large">
            <Stack direction="row" spacing="regular" p="medium">
              <Stack direction="row" spacing="regular" alignItems="center">
                <div {...dragProvider.dragHandleProps}>
                  <Icon
                    as={RxDragHandleDots2}
                    fontSize="larger"
                    marginLeft="-18px"
                    style={{ transitionDuration: `0.001s` }}
                    cursor={hasOnly ? 'not-allowed' : 'move'}
                  />
                </div>

                {isFlowEditing && setCheckbox && (
                  <Checkbox
                    value={`nodes.${nodeIndex}`}
                    onChange={(e) => handleCheckboxValue(e)}
                  />
                )}

                <S.StyledEditable
                  value={String(nodeIndex)}
                  isDisabled={hasOnly || !isFlowEditing}
                  onChange={handleChange}
                >
                  <S.StyledEditablePreview />
                  <EditableInput
                    as="select"
                    _focus={{ background: 'white.200' }}
                    _active={{
                      background: 'white.200',
                    }}
                  >
                    {nodes.map((nodePath, nodePathIndex) => {
                      const key = `index_options_${nodeIndex}-${nodePath.id}-${nodePathIndex}`;
                      return (
                        <option key={key} value={nodePathIndex}>
                          {nodePathIndex}
                        </option>
                      );
                    })}
                  </EditableInput>
                </S.StyledEditable>
              </Stack>

              <div>
                <Divider orientation="vertical" borderColor="neutral.400" />
              </div>

              {isEditing ? (
                <Wrap spacing="regular">
                  <WrapItem>
                    <InputText
                      label="Nó"
                      {...register(`nodes.${nodeIndex}.title`)}
                      formcontrol={{
                        isRequired: true,
                        error: errors.nodes?.[nodeIndex]?.title,
                        style: { width: '25%' },
                      }}
                    />
                  </WrapItem>
                  <WrapItem>
                    <InputSelect
                      label="Tipo"
                      options={typeOptions}
                      {...register(`nodes.${nodeIndex}.operator`)}
                      defaultValue={node.operator}
                      formcontrol={{
                        isRequired: true,
                        error: errors.nodes?.[nodeIndex]?.operator,
                      }}
                    />
                  </WrapItem>

                  <WrapItem>
                    <InputSelect
                      label="Política de erro"
                      options={policyOptions}
                      {...register(`nodes.${nodeIndex}.error_policy`)}
                      formcontrol={{
                        isRequired: true,
                        error: errors.nodes?.[nodeIndex]?.error_policy,
                      }}
                    />
                  </WrapItem>
                  <WrapItem>
                    <InputSelect
                      label="Ação"
                      options={actionOptions}
                      {...register(`nodes.${nodeIndex}.action`)}
                      formcontrol={{
                        error: errors.nodes?.[nodeIndex]?.action,
                      }}
                    />
                  </WrapItem>
                  {actionSelected === 'delegar' && !isBlock && !isRetention && (
                    <WrapItem>
                      <InputText
                        label="Bloco ID"
                        {...register(`nodes.${nodeIndex}.relay_to`)}
                        formcontrol={{
                          isRequired: true,
                          error: errors.nodes?.[nodeIndex]?.relay_to,
                          style: { width: '25%' },
                        }}
                      />
                    </WrapItem>
                  )}
                </Wrap>
              ) : (
                <Information node={node} nodeIndex={nodeIndex} />
              )}

              <Stack direction="row" marginLeft="auto !important">
                {!isEditing && (
                  <Button
                    onClick={handleEnableEdit}
                    isDisabled={!isFlowEditing}
                  >
                    <Icon as={BiEditAlt} />
                  </Button>
                )}
                <Button
                  onClick={handleRemoveNode}
                  isDisabled={hasOnly || !isFlowEditing}
                >
                  <Icon as={HiOutlineTrash} />
                </Button>
              </Stack>

              <AccordionButton w="fit-content" borderRadius="8px">
                <AccordionIcon />
              </AccordionButton>
            </Stack>

            <AccordionPanel borderTop="1px solid" borderColor="neutral.400">
              {terms.map((term, termIndex) => {
                const key = `term_${term.id}-${termIndex}`;
                return (
                  <TermsBase
                    key={key}
                    term={term}
                    terms={terms}
                    termIndex={termIndex}
                    nodeIndex={nodeIndex}
                    isEditing={isEditing}
                    isRetention={isRetention}
                    appendTerms={appendTerms}
                    removeTerms={removeTerms}
                  />
                );
              })}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  isDisabled={!isEditing}
                  leftIcon={<Icon as={CgAddR} />}
                  onClick={handleAppendTerms}
                >
                  Adicionar regra
                </Button>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </div>
      )}
    </Draggable>
  );
};

export default Node;
