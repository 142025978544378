import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import GenericForm from '../../../components/base/v2/genericForm';
import ModelUpsertForm from '../../../sections/models/form';
import { emptyModelsObject } from '../../../providers/models/utils';

import { ModelsModel } from '../../../interfaces/models';
import useModels from '../../../hooks/api/models';
import { formatConstants } from '../../../utils/formatConstants';
import { constants } from '../../../constants';
import { useRulesModels } from '../../../providers/models';

const ModelsCreate: React.FC = () => {
  const { setIsDisabled } = useRulesModels();
  const { createModel } = useModels();
  const { push } = useHistory();

  const handleSubmit = async (data: ModelsModel) => {
    try {
      const res = await createModel(data);

      const message = formatConstants(constants.updatedWithSuccess, 'Modelo');
      toast.success(message);
      push(`/models/info/${res.data.id}`);
    } catch (err: unknown) {
      if (err instanceof Error) {
        toast.error(`${constants.errorToSubmitWithReason}: ${err.message}`);
      }
    }
  };

  useEffect(() => {
    setIsDisabled(false);
  }, []);

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Modelos', href: '/models' },
        { label: 'Cadastro' },
      ]}
      showBackButton
      title="Cadastro de Modelos"
      backButtonUrl="/models"
      actionText="Criar Modelo"
    >
      <ModelUpsertForm model={emptyModelsObject} handleSubmit={handleSubmit} />
    </GenericForm>
  );
};

export default ModelsCreate;
